
import React, {useState, useEffect} from 'react'
import { formatTimestamp, formatCustomDate, calculateDaysTillToday, dateDiffInDays, extractDateFromTimestamp } from '../../helpers/dateFormatter';
import { Button, Input } from '@nextui-org/react';
import { userStore } from '../../store/userStore';
import authQueries from "../../queries/authQueries";
import {
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	useDisclosure
  } from "@nextui-org/react";


export default function Issueview({compliant, handleOpen, actioncategory, setActioncategory, setAcceptText, acceptText, handleAccept, accepError}) {
	const store  = userStore()
	const categoriesInformant = compliant?.categories?.filter(item => item.complaint_category && item.complaint_category.status === 'ACTIVE' && item.complaint_category.created_by === null )
  	.map(item => item.category);

	const categoriesNew = compliant?.categories?.filter(item => item.complaint_category && item.complaint_category.status === 'ACTIVE' && item.complaint_category.created_by !== null)
  	.map(item => item.category);

	const categoryString = categoriesInformant?.join(', ');
	const categoryNew = categoriesNew?.join(', ');
	const [history,setHistory] = useState([]);
	const {isOpen, onOpen, onOpenChange} = useDisclosure();
	const [responseContent, setResponseContent] = useState('');
	const [isOpenModal2, setIsOpenModal2] = useState(false);
	const [isOpenModal3, setIsOpenModal3] = useState(false);
	const [isOpenModal4, setIsOpenModal4] = useState(false);
	const [isOpenModal5, setIsOpenModal5] = useState(false);
    const [categoryHistory, setcategoryhistory]= useState([]);
	const [lessonLearned, setlessonLearned]=useState('')
	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
		timeZone: 'UTC',
	  }


	  console.log("compliant?.shipconrep_contact_informant_at",compliant)
	//   useEffect(() => {
	// 	const fetchData = async () => {
	// 	  if (compliant?.categories) {
	// 		await filterComplaintData(compliant?.categories);
	// 		if (history?.length > 0) {
	// 			let highestDatetime = null;
	// 			let objectWithHighestDatetime = null;
	// 			for (const item of history) {
	// 				const datetimeString = item.datetime;
	// 				const datetime = new Date(datetimeString);
				
	// 				if (!isNaN(datetime.getTime()) && (highestDatetime === null || datetime > highestDatetime)) {
	// 					highestDatetime = datetime;
	// 					objectWithHighestDatetime = item;
	// 				}
	// 			}
	// 			if (objectWithHighestDatetime) {
	// 				// console.log("Object with the highest date-time:", new Date(objectWithHighestDatetime.datetime).toLocaleDateString('en-IN', options));
	// 			} else {
					

	// 				// console.log("No valid date-time values found in the array.");
	// 			}
	// 		  setActioncategory(objectWithHighestDatetime);
			  
	// 		}
	// 	  }
	// 	};
	// 	setcategoryhistory(history)
	// 	fetchData();
	//   }, [compliant?.categories]);

	  const userView = authQueries.useUserviewMutation(
		(response) => {
		}
	  );

	  async function userDetails(id){
		const details = await userView.mutateAsync({id: id });
		return details;

	  }

	//   async function filterComplaintData(data) {

	// 	const filteredData = data.map(async (item) => {
			
	// 		setHistory([])   
	// 	  if (
	// 		item.complaint_category.created_by !== null &&
	// 		item.complaint_category.updated_by !== null &&
	// 		item.complaint_category.status === 'ACTIVE'
	// 	  ) {
	// 		const formattedDate = new Date(item.complaint_category.updated_at);
	// 		const details =await userDetails(item.complaint_category.created_by);
	// 		setHistory((prevHistory) => [
	// 		  ...prevHistory,
	// 		  { actioncategory: "Category Updated", datetime: item.complaint_category.updated_at, by: details?.user?.fname+' '+details?.user?.lname },
	// 		]);
	// 		// console.log("actioncategory",history)
	// 		return {
	// 		  id: item.id,
	// 		  updated_at: formattedDate.toLocaleDateString('en-US', options),
	// 		  updated_by: item.complaint_category.updated_by,
	// 		};
	// 	  } else if (item.complaint_category.created_by !== null && item.complaint_category.status === 'ACTIVE') {
	// 		const formattedDate = new Date(item.complaint_category.created_at);
	// 		const details =await userDetails(item.complaint_category.created_by);
	// 		setHistory((prevHistory) => [
	// 		  ...prevHistory,
	// 		  { actioncategory: "Category Created", datetime: item.complaint_category.created_at, by: details?.user?.fname+' '+details?.user?.lname },
	// 		]);
	// 		// console.log("actioncategory",history)
	// 		return {
	// 		  id: item.id,
	// 		  created_at: formattedDate.toLocaleDateString('en-IN', options),
	// 		  created_by: item.complaint_category.created_by,
	// 		};
	// 	  } else if (item.complaint_category.created_by !== null && item.complaint_category.updated_by !== null && item.complaint_category.status === 'INACTIVE') {
	// 		const formattedDate = new Date(item.complaint_category.updated_at);
	// 		const details =await userDetails(item.complaint_category.created_by);
	// 		// console.log("actioncategory",history)
	// 		setHistory((prevHistory) => [
	// 		  ...prevHistory,
	// 		  { actioncategory: "Category Removed", datetime: item.complaint_category.updated_at, by: details?.user?.fname+' '+details?.user?.lname },
	// 		]);
	// 	  }
	// 	  // If neither condition is met, skip this item
	// 	  return null;
	// 	});
	 

	// 	// Filter out the null values (items that didn't meet the conditions)
	// 	return filteredData.filter((item) => item !== null);
	//   }
	  

	  const handleDownload =(item)=> {
		window.open(item)
	  }

	  const handleResponsemessge = (msg) =>{
		setResponseContent(msg);
		onOpen();

	  }

	  const accountuserHistory = () =>{
		if(isOpenModal2){
			setIsOpenModal2(false)
		}else{
			setIsOpenModal2(true)
		}
		
	  }


	  const issueName=compliant?.complaint_assigns?.filter(assign=> assign.isaccept==='YES');
	  const openDaysAccepted= compliant?.complaint_assigns?.filter((item)=> item.isaccept ==='YES')
	  const issueCreated_at=extractDateFromTimestamp(compliant?.created_at);
	  
	  let acceptedDate=false;
	  if(openDaysAccepted?.length>0){
		acceptedDate= extractDateFromTimestamp(openDaysAccepted?.[0]?.updated_at);
	  }
	  let closedDate=''
	  if(compliant?.complaint_status?.status==='CLOSED'){
		closedDate = extractDateFromTimestamp(compliant?.issue_closed_at);
		const created_date=extractDateFromTimestamp(compliant?.created_at);
		closedDate=dateDiffInDays( created_date, closedDate)

		
	  }
  return (
	<>
        <div class={compliant?.complaint_status?.status !== "CLOSED" ? "col-md-6" : "col-md-12" }>
			<h5>Issue # {issueName?.[0]?.nameofissue? issueName?.[0]?.nameofissue:compliant?.reference_num}</h5>
			
			<div class="card">
				<div class="card-body">

					<div className="row">

						<div className='col-md-6'>
							<div class="detail-group">
								<label>Issue Reported on</label>
								<div> 
								{compliant?.created_at ? ( <p>{formatTimestamp(compliant?.created_at)}</p>) : (null)} 
								</div>
							</div>
						</div> 

						<div className='col-md-6'>
							<div class="detail-group">
								<label>Reference Number</label>
								<div> 
								{compliant?.reference_num} 
								</div>
							</div>
						</div> 

						<div className='col-md-6'>
							<div class="detail-group">
								<label>Number Of Days Open</label>
								<div> 
								{ compliant?.complaint_status?.status==='OPEN'?( <p>{calculateDaysTillToday(issueCreated_at)}</p>) : 
								  compliant?.complaint_status?.status==='ACCEPTED' ?( <p>{calculateDaysTillToday(issueCreated_at)}</p>):  
								 compliant?.complaint_status?.status==='CLOSED' ?( <p>{closedDate}</p>):null } 
								</div>
							</div>
						</div> 

					</div>

				
						<div class="detail-group">
						{
					      compliant?.complaint_status?.status === "CLOSED"?
						  <label>Issue Category Reported</label>:null
						}
						{
					      compliant?.complaint_status?.status !== "CLOSED" &&
							<div class="label-and-button">
								<label>Issue Category Reported</label>
								<Button
								title='Click here if you want to change Category'
								color="primary" 
								size="sm" 
								radius="full"  
								onClick={handleOpen}  
								disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? true: false}
								className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}	  
								>Assign New Category</Button>
							</div>
					    }
							<div><p>{categoryString}</p></div>
							
						</div>

						
						<div class="detail-group">
							<div class="label-and-button">
							<label>Issue Category Updated</label>
							</div>
							<div><p>{categoryNew} </p></div>
						</div>
					

						{compliant?.category_other !== null && (
						<div class="detail-group">
							<div class="label-and-button">
							<label>Other Issue Category Reported</label>
							</div>
							<div><p>{compliant?.category_other} </p></div>
						</div>
					    )}

						
					 
					

					<div class="detail-group">
						<label>Issue Related to</label>
						<div>
							<p>DOC Holder: {compliant?.doc_holder?.name} - {compliant?.doc_holder?.imo}</p>
						</div>
					</div>
					
					<div class="detail-group">
						<label class="mb-2">What Happened</label>
						<div class="what-happend-content">
							{compliant?.what_happened}
						</div>
					</div>
					
					<div class="detail-group">
						<label>What Caused It</label>
						<div>
							<p>{compliant?.what_caused}</p>
							
						</div>
					</div>
					
					<div class="detail-group">
						<label>What Needs to be done</label>
						<div>
							<p>{compliant?.what_need_tobe_done}</p>
							
						</div>
					</div>

			    {compliant?.complaint_status?.status === "CLOSED" && (
			    <>
					{/* <div class="detail-group">
						<label>Lesson Learned</label>
						<div>
							<p>{compliant?.lesson_learned}</p>
							
						</div>
					</div> */}
					<div class="detail-group">
						<label>How Issue Closed</label>
						<div>
							<p>{compliant?.how_issue_closed}</p>
						</div>
					</div>

					<div class="detail-group">
						<label>Response Sent to Reporter</label>
						<div>
							<p>{compliant?.company_send_response}</p>
						</div>
					</div>
			    </>
				)}

					{compliant?.callback ==='YES'? 
					<div class="detail-group">
						<label>Reporter Request</label>
						<div>
							<p>Reporter has requested SHIPCONREP to make contact.</p>
						</div>
					</div>
					:null
					}
					
					<div class="detail-group">
						<label>Attachment</label>
						<div class="attachment-links">
								{compliant?.complaint_attachments?.map((item, index)=>{ 
									let fileName=''
									if (item?.file_name?.includes("/")){
										console.log("item?.file_name",item?.file_name)
										const urlObject = new URL(item?.file_name);
										const pathname = urlObject.pathname;
										fileName = pathname.split('/').pop();
									}
									
								 	return(
									(item?.file_type === "image/png" || item?.file_type === "image/jpg" || item?.file_type === "image/svg+xml" || item?.file_type === "image/jpeg") ?
									<div><a target='_blank' key={index} onClick={()=> {handleDownload(item?.file_name)}} style={{height:'130px', cursor:'pointer'}}>
										<div>
											<img src={`${process.env.REACT_APP_BASE_URL}/api/fileupload/${item?.file_name}`} />
										</div>
										
									</a><p className='attachement-text'>{fileName}</p></div>:
									<div><a target='_blank' key={index} onClick={()=> {handleDownload(item?.file_name)}} style={{height:'130px', cursor:'pointer', color:'#fff'}}>
										<i class="icon-download8"></i>
									</a>
									<p className='attachement-text'>{fileName}</p></div>
									// <button onClick={()=> {handleDownload(item?.file_name)}}>Download</button>

									
								)})}
								{compliant?.complaint_attachments?.length>0?null:<p>No Attachment</p>}
								
							{/* <a href="#" class="venobox audio-attachment">
								<i class="icon-volume_up"></i>
							</a>
							<a href="https://www.youtube.com/watch?v=jNE6bv-TAEk&ab_channel=CruisePassion" data-autoplay="true" data-vbtype="video" class="venobox video-attachment">
								<i class="icon-youtube"></i>
							</a> */}
							
						</div>
					</div>
					{!acceptedDate?(
					<div class="detail-group">
						
						<div>
							<a href="#" >This issue is waiting to be accepted by {compliant?.complaint_assigns?.[0]?.app_user?.fname}  {compliant?.complaint_assigns?.[0]?.app_user?.lname}</a>
						</div>
					</div>):(
						<div class="detail-group">
						
						<div>
							<a href="#" >This issue has been accepted by {openDaysAccepted?.[0]?.app_user?.fname}  {compliant?.complaint_assigns?.[0]?.app_user?.lname} on { acceptedDate }</a>
						</div>
					</div>

					)
					}
					
					
					<div class="history-table-wrpr">
						<h5 class="mb-3 blue-label">Action History</h5>
						<table class="table table-striped">
							<thead>
							  <tr>
								<th>Action</th>
								<th>Date</th>
								<th>By</th>
							  </tr>
							</thead>
							<tbody>

							{
								compliant?.categories?.map((category, index) => {
									if (
										category.complaint_category.created_by !== null &&
										category.complaint_category.updated_by !== null &&
										category.complaint_category.status === 'ACTIVE'
									  ) {
										return(
											<tr key={index}>
											<td>Category Updated</td>
										    <td>{formatCustomDate(category.complaint_category.updated_at, options)}</td>
											<td>{category.complaint_category.updatedBy}</td>
											</tr>
										)
									  }
									  else if (
										category.complaint_category.created_by !== null && category.complaint_category.status === 'ACTIVE'
									  ) {
										return(
											<tr key={index}>
											<td>Category Created</td>
										    <td>{formatCustomDate(category.complaint_category.created_at, options)}</td>
											<td>{category.complaint_category.createdBy}</td>
											</tr>
										)
									  }
									  else if(category.complaint_category.created_by !== null && category.complaint_category.updated_by !== null && category.complaint_category.status === 'INACTIVE')
									  {
										return(
											<tr key={index}>
											<td>Category Removed</td>
										    <td>{formatCustomDate(category.complaint_category.updated_at, options)}</td>
											<td>{category.complaint_category.updatedBy}</td>
											</tr>
										)
									  }
							})
							}


							{/* {categoryHistory?.map((item, index) => (
							  <tr>
								<td>{item.actioncategory}</td>
								<td>{  formatCustomDate(item?.datetime, options) }</td>
								<td>{item.by}</td>
							  </tr>
							))}	 */}


							{compliant?.complaint_assigns?.map((item, index) => (
								<tr>
									<td className='accounthistory'  data-bs-toggle="tooltip"  title='click here to see the entire history' onClick={()=>{accountuserHistory()}}>{index  + 1 === compliant?.complaint_assigns?.length ? "Account User Assigned": "Reassign User"}<i style={{fontSize:'18px'}} class="icon-popout"></i></td>
									<td>{formatCustomDate(item?.created_at, options)}</td>
									<td>{index  + 1 === compliant?.complaint_assigns?.length ? item?.app_user?.fname+" "+item?.app_user?.lname :item?.assigned_by_user?.fname+" "+item?.assigned_by_user?.lname }  </td>
								</tr>
							))}
							{compliant?.complaint_assigns?.map((item, index) => (
								item?.isaccept === "YES" &&
								<tr>
									<td>Issue Accepted</td>
									<td>{formatCustomDate(item?.updated_at, options)}</td>
									<td>{item?.app_user?.fname} {item?.app_user?.lname}</td>
								</tr>
							))}
							
							{compliant?.shipconrep_contact_informant==='YES' && compliant?.is_contact_informant === 1?
							  <tr>
								<td className='responseMessagepointer' data-bs-toggle="tooltip" onClick={()=>{ setIsOpenModal3(true)}} >Contact Reporter Request <i style={{fontSize:'18px'}} class="icon-popout"></i></td>
								<td>{ formatCustomDate(compliant?.shipconrep_contact_informant_at, options)}</td>
								<td>{compliant?.contact_informant_by?.fname+' '+compliant?.contact_informant_by?.lname}</td>
							  </tr>:null
							}

							{compliant?.informant_responses?.map((item, index) => (
								
								<tr>
									<td className='responseMessagepointer' onClick={()=>{handleResponsemessge(item?.response)}}>{item?.is_company_send_response_schedule_later ==='TRUE'? 'Response Scheduled Later to Contact Reporter':'Response Sent to Reporter'}<i style={{fontSize:'18px'}} class="icon-popout"></i></td>
									<td>{formatCustomDate(item?.company_send_response_at, options)}</td>
									<td>{item?.response_by?.fname} {item?.response_by?.lname}</td>
								</tr>
							))}
{/* 
							{compliant?.company_send_response_schedule_later ==='TRUE'?
							  <tr>
								<td data-bs-toggle="tooltip" title={compliant?.company_send_response} >Response Scheduled Later to Contact Informant</td>
								<td>{ formatCustomDate(compliant?.email_schedule?.mail_schedule_time, options)   }</td>
								<td>{compliant?.email_schedule?.mail_Scheduled_by?.fname+' '+compliant?.email_schedule?.mail_Scheduled_by?.lname }</td>
							  </tr>:null
							  }	

							{compliant?.is_company_send_response_informant==='YES'?
							  <tr>
								<td data-bs-toggle="tooltip" title={compliant?.company_send_response}>Response Sent to Informant</td>
								<td>{compliant?.company_send_response_at}</td>
								<td>{compliant?.send_response_by?.fname+' '+compliant?.send_response_by?.lname}</td>
							  </tr>:null
							}		 */}


							{
								compliant?.is_issue_closed==='YES'?
								<tr>
									<td data-bs-toggle="tooltip" onClick={()=>{ setIsOpenModal4(true)}} title={compliant?.how_issue_closed}>Issue closed<i style={{fontSize:'18px'}} class="icon-popout"></i></td>
									<td>{formatCustomDate(compliant?.issue_closed_at, options)}</td>
									<td>{compliant?.closed_by?.fname+' '+compliant?.closed_by?.lname}</td>
							 	 </tr>:null
							}

							{compliant?.complaint_lesssons?.map((item, index) => (
							  <tr>
								<td data-bs-toggle="tooltip" onClick={()=>{ setlessonLearned(item?.lesson); setIsOpenModal5(true)}} title={item?.lesson}>Lesson Learned<i style={{fontSize:'18px'}} class="icon-popout"></i></td>
								<td>{formatCustomDate(item?.created_at, options)}</td>
								<td>{item?.lesson_created_user?.fname+' '+item?.lesson_created_user?.lname}</td>
							  </tr>
							))}	
							 
							 {compliant?.is_shipconrep_contact_informant_cancelled==='YES' || compliant?.is_shipconrep_contact_informant_cancelled===1?
							  <tr>
								<td>Contact Reporter Cancelled</td>
								<td>{formatCustomDate(compliant?.shipconrep_contact_informant_cancelled_at, options)}</td>
								<td>{compliant?.cancell_informant_by?.fname+' '+compliant?.cancell_informant_by?.lname}</td>
							  </tr>:null
							}	
							
							{compliant?.callback ==='YES'? 
							  <tr>
								<td>Call back as requested</td>
								<td>{ formatCustomDate(compliant?.informant_callback?.callback_time, options)  }</td>
								<td></td>
							  </tr>:null
							  }		

							</tbody>
						  </table>
					</div>


					{store?.user?.role === "ACCOUNTUSER" &&  compliant?.complaint_status?.status !== "CLOSED" &&
						(
							compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) &&
							<div className='text-primary font-medium text-[13px] mt-[30px] mb-[10px]'>
								<span>This issue assigned to you. Please assign a name/office code & accept this issue to proceed further</span>
								<div className='mt-[20px] flex items-center justify-between gap-4'>
									<Input onChange={(e)=>{setAcceptText(e.target.value)}} radius='sm' className='text-[10px] text-black' label="" placeholder="Name this issue..." />
									<Button onPress={handleAccept} className='w-32' color="primary" size="md" radius="sm" >I Accept</Button>
								</div>
								<div className="error-msg">
									{accepError}
								</div>
							</div>
						)
					}

				</div>
			</div>
			
		</div>
		
{/* response sent	 */}
      <Modal size='3xl' isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Response Content</ModalHeader>
              <ModalBody>
               {responseContent}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

{/* Additional  Message */}
      <Modal size='3xl' isOpen={isOpenModal3} onOpenChange={(newState)=>setIsOpenModal3(newState)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Additional Message to SHIPCONREP</ModalHeader>
              <ModalBody>
               {compliant?.additional_msgto_shipconrep}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


{/* Account user History	 */}
	  <Modal size='sm' isOpen={isOpenModal2} onOpenChange={(newOpenState) => setIsOpenModal2(newOpenState)} >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Account user History</ModalHeader>
              <ModalBody>
             <div className='row'>
				<div className='col-md-6'>
					<p><b>user assigned</b></p>
					{compliant?.complaint_assigns?.map((item, index) => (
                      <p>  {index  + 1 === compliant?.complaint_assigns?.length ? "• "+item?.app_user?.fname+" "+item?.app_user?.lname:null }</p>
					))}
				</div>
				<div className='col-md-6'> 
					<p><b>user reassigned</b></p>
					{compliant?.complaint_assigns?.map((item, index) => (
                       <p>{index  + 1 === compliant?.complaint_assigns?.length ?null:"• "+item?.app_user?.fname+" "+item?.app_user?.lname }</p>
					))}
				</div>
			 </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


	  {/* closed  Message */}
      <Modal size='3xl' isOpen={isOpenModal4} onOpenChange={(newState)=>setIsOpenModal4(newState)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Closed Message</ModalHeader>
              <ModalBody>
               {compliant?.how_issue_closed}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

	  	  {/* Lesson  Learned */}
			<Modal size='3xl' isOpen={isOpenModal5} onOpenChange={(newState)=>setIsOpenModal5(newState)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Lesson Learned</ModalHeader>
              <ModalBody>
               {lessonLearned}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


	</>	

  )}