import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import {Spinner} from "@nextui-org/react";
import RouteConstants from "../../../Constants/RouteConstant";

const validationSchema = yup.object({
  name: yup
    .string("⚠ Enter Vessel Name")
    .required("⚠ Vessel Name is required")
    .min(2, "⚠ Vessel Name must be more than 2 characters "),
  doc_holder_id: yup
    .string("⚠ Select Doc Holder")
    .required("⚠ Doc Holder is required"),
  fleet_id: yup
    .string("⚠ Select Fleet")
    .required("⚠ Fleet is required"),
  user_id: yup
    .string("⚠ Select Account User")
    .required("⚠ Account User is required"),
  vessel_imo: yup
    .string("⚠ Enter  IMO")
    .required("⚠ IMO is required")
    .min(2, "⚠ IMO must be more than 2 characters ")
    .test('is-integer', '⚠ IMO number is required', (value) => {
      if (!value) return true; // Allow empty values (handled by "required" rule)
      return Number.isInteger(Number(value));
    }),
  contact_email: yup
    .string("⚠ Enter a valid email")
    .email("⚠ Enter a valid email address"),
  doc_super_user: yup
  .string("⚠ Please Select the doc Holder")
  .required("⚠ Doc Super User is required")
});

const VesselList = () => {
  const [result, setResult] = useState([]);
  const [docholder, setHocholder] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [countrySelect, setCountrySelect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isbuttonLoading, setIsbuttonLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [vesselErr, setVesselErr] = useState(false);
  const [vesselImoErr, setvesselImoErr] = useState(false);
  const [docData, setDocData]=useState('');
  const companyID = userStore();
  const InitCall =async (id)=> {

    setIsModalLoading(true); 
    await docListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:companyID?.companyData?.company}).then(()=>{
      setIsModalLoading(false); 
    });
    // await fleetListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:companyID?.companyData?.company}).then(()=>{
    //   setIsModalLoading(false); 
    // })

  }

  useEffect(() => {
    let FilterValues={
      selectedDoc:'',
      selectedVessel:'',
      selectedAccntStatus:'',
      selectedCategory:'',
      selectedPlatform:'',
      selectedReference:'',
      selectedVesselKey:'',
      selectedFleet:'',
      selectedDocKey:'',
      selectedFleetkey:''   
      
      
}
      companyID.setSelectedFilter(FilterValues);
       setIsLoading(true); 
       mutateAsync({ search: "", status: "",doc:"", fleet:"", page: 0, size: 10, schema: companyID?.companyData?.company })
      .then(() => {
        setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false); 
      });
  }, []);

  useEffect(() => {
    setIsLoading(true); 
    mutateAsync({ search: companyID?.companyFilter?.selectedVesselKey, doc:companyID?.companyFilter?.selectedDoc, fleet:companyID?.companyFilter?.selectedFleet, status: companyID?.companyFilter?.selectedAccntStatus, page: 0, size: 10, schema: companyID?.companyData?.company })
   .then(() => {
     setIsLoading(false); 
   })
   .catch(() => {
     setIsLoading(false); 
   });
}, [companyID]);



  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useVesselListMutation((response) => {
    setResult(response.data);
  });

  const handleSelectChange = (event) => { 
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSelectDoc = async (event) => {
    setValue("user_id","")
    setValue("fleet_id", "") 
    const newValue = event.target.value;
    await userListing.mutateAsync({id:newValue, schema: companyID?.companyData?.company });
    const doc = docholder.find(item => item.id === parseInt(newValue));
    const filterSuperuser= doc?.user_doc_relations?.filter(user=> user?.app_user?.user_role?.roleid===3);
    console.log("filterSuperuser", filterSuperuser[0]?.app_user?.fname)
    setIsModalLoading(false); 
    if(filterSuperuser){
      setValue("doc_code", doc.doc_code)
      if(filterSuperuser[0]?.app_user){
        setValue("doc_super_user", `${filterSuperuser[0]?.app_user?.fname} ${filterSuperuser[0]?.app_user?.lname}`)
      }else{
        setValue("doc_super_user", "") 
      }
    }else{
      setValue("doc_code", "")
      setValue("doc_super_user", "")
    }
    setValue("doc_holder_id", newValue); 
  };

  
  const handleSelectFleet = (event) => {
    const newValue = event.target.value;
    setValue("fleet_id", newValue);
  };

  const handleSelectUser = (event) => {
    const newValue = event.target.value;
    setValue("user_id", newValue);
  };

  function removeDuplicates(array) {
    const uniqueIds = new Set();
  
    // Filter the array to include only unique objects based on "id"
    const uniqueArray = array.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  
    return uniqueArray;
  }

  const docListing = authQueries.useDocVesselListMutation((response) => {
    const deduplicatedArray = removeDuplicates(response.data?.items);
    setHocholder(deduplicatedArray);
  });
  

  const fleetListing = authQueries.useFleetListMutation(
    (response) => {
      setFleet(response.data?.items);
    }
  );

  const userListing = authQueries.useAccountUserForVesselListingDocMutation(
    (response) => {
      setUser(response?.data);
      setFleet(response?.Fleets);
    }
  );

  const setInitialValue = async  (data) => {
    await userListing.mutateAsync({id:data.doc_holder?.id, schema: companyID?.companyData?.company });
    await viewData.mutateAsync({id: data.id, schema:companyID?.companyData?.company}).then((resp)=>{
    const filterSuperuser= resp?.data?.doc_holder?.user_doc_relations?.filter(user=> user?.app_user?.user_role?.roleid===3);
    setIsModalLoading(true); 
    InitCall(data?.app_user?.id);
    setEditValue(data);
    let datas = {
      id: data.id,
      doc_holder_id: data.doc_holder?.id,
      fleet_id: data.fleet?.id,
      user_id:  data.app_user?.id,
      name: data.name,
      vessel_imo: data.vessel_imo,
      status: data.status,
      doc_code: data.doc_holder?.doc_code,
      
    };
  
    setSelectedValue(data.status)
    reset({ ...datas });
    
    if(filterSuperuser){
    
      datas = {
        id: data.id,
        doc_holder_id: data.doc_holder?.id,
        fleet_id: data.fleet?.id,
        user_id:  data.app_user?.id,
        name: data.name,
        vessel_imo: data.vessel_imo,
        status: data.status,
        doc_code: data.doc_holder?.doc_code,
        doc_super_user: filterSuperuser?.[0]?.app_user?.fname+' '+filterSuperuser?.[0]?.app_user?.lname
      };
      setValue('doc_super_user', `${filterSuperuser?.[0]?.app_user?.fname+' '+filterSuperuser?.[0]?.app_user?.lname}`)
    }else{
      setValue("doc_holder_user", '');
    }
  
    reset({ ...datas });

    onOpen(true);
    setIsModalLoading(false); 

    })
    
  };

  const AddData = authQueries.useVesselAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.VESSEL);
    } else if (response?.error[0]?.name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useVesselUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: "",  page: 0, size: 10,doc:companyID?.companyFilter?.selectedDoc, fleet:companyID?.companyFilter?.selectedFleet, status: companyID?.companyFilter?.selectedAccntStatus,  schema:companyID?.companyData?.company});
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useVesselMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 ,doc:companyID?.companyFilter?.selectedDoc, fleet:companyID?.companyFilter?.selectedFleet, status: companyID?.companyFilter?.selectedAccntStatus,  schema:companyID?.companyData?.company});
    messages.success("successfully deleted");
  });

  const vesselchecking = authQueries.useVesselExistMutation(
    (response) => {
    }
  );

  const vesselImochecking = authQueries.useVesselImoExistMutation(
    (response) => {
    }
  );


  const viewData = authQueries.useVesselViewMutation((response) => {
    setDocData(response?.data)
  });


  const onSubmit = async (data) => {
    try {
      const vesselExistsResponse = await vesselchecking.mutateAsync({ vessel: data.name,id: editValue?.id || '', schema:companyID?.companyData?.company });
    
      if (vesselExistsResponse.exists) {
        setVesselErr(true);
        setIsbuttonLoading(false);
        return; // Exit the function early
      }else{
        setVesselErr(false);
      }
    
      const vesselImoExistsResponse = await vesselImochecking.mutateAsync({ imo: data.vessel_imo,id: editValue?.id || '', schema:companyID?.companyData?.company });
    
      if (vesselImoExistsResponse.exists) {
        setvesselImoErr(true);
        setIsbuttonLoading(false);
        return; // Exit the function early
      }else
      {
        setvesselImoErr(false);
      }
    
    } catch (error) {
      console.error("Error checking vessel existence:", error);
      return; // Exit the function early in case of an error
    }
    
    setIsbuttonLoading(true);
    const datavalues = {
      doc_holder_id: data.doc_holder_id,
      fleet_id: data.fleet_id,
      user_id:  data.user_id,
      name: data.name,
      vessel_imo: data.vessel_imo,
      status: selectedValue,
      schema:companyID?.companyData?.company
    };
    if (!editValue) {
      AddData.mutateAsync(datavalues).then(() => {
        mutateAsync({ search: "", status: "", page: 0, size: 10, schema:companyID?.companyData?.company }).then(()=>
        {
          onOpenChange(false);
          setIsbuttonLoading(false);
          let datas = {
            name: "",
            vessel_imo: "",
          };
          setSelectedValue("ACTIVE")
          reset({ ...datas });
        })
       
      });
    } else {
      const datavalues = {
        id: editValue.id,
        doc_holder_id: data.doc_holder_id,
        fleet_id: data.fleet_id,
        user_id:  data.user_id,
        name: data.name,
        vessel_imo: data.vessel_imo,
        status: selectedValue,
        schema:companyID?.companyData?.company
      };
      updateData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false);
        let datas = {
          name: "",
          vessel_imo: "",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    setIsLoading(false);
    event.preventDefault();
    deleteData.mutateAsync({id:id,schema:companyID?.companyData?.company}).then(()=>{
      setIsLoading(false);
    })
  };

  const addButton = ()=>{
    setEditValue(null);
    let datas = {
      name: "",
      vessel_imo: "",
    };
    reset({ ...datas });
    onOpen(true);
    InitCall();

  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Vessel </h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
            <Button
              color="primary"
              className="btn btn-add"
              onPress={addButton}
            >
              <i className="icon-add"></i> Add
            </Button>
          </div>
          {isLoading ? (
          <div className="loader" >
            <Spinner size="large" />
          </div>
           ) : (
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Doc Holder</th>
                    <th width="100">Vessel - IMO</th>
                    <th width="100">Fleet</th>
                    <th width="100">User</th>
                    <th width="100">Emp Code</th>
                    <th width="100">Status</th>
                    <th width="100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item?.doc_holder?.name}</td>
                      <td>{item?.name} - {item?.vessel_imo}</td>
                      <td>{item?.fleet?.name}</td>
                      <td>{item?.app_user?.username}</td>
                      <td>{companyID?.company?.company?.comp_code+'V'+item?.doc_holder?.doc_code}</td>
                      <td>
                      <span className={`status ${item.status === 'ACTIVE' ? 'open' : 'inactive'}`}>{item.status}</span>
                      </td>
                      <td className="action-links">
                      <a
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                           <i className="icon-edit"></i>
                        </a>
                      {companyID?.user?.role==='SHIPCONREPADMIN'?
                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this ?"
                          onConfirm={(event) => {
                            deleteEvent(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view"> <i className="icon-delete"></i></button>
                        </Popconfirm>:null
                      }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          )}
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {mutateAsync({search: companyID?.companyFilter?.selectedVesselKey, fleet:companyID?.companyFilter?.selectedFleet, doc:companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page: e-1 , size:pageSize, schema:companyID?.companyData?.company},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
        handleSelectDoc={handleSelectDoc}
        docholder={docholder}
        fleet={fleet}
        handleSelectFleet={handleSelectFleet}
        user={user}
        handleSelectUser={handleSelectUser}
        isLoading={isbuttonLoading}
        isModalLoading={isModalLoading}
        vesselErr={vesselErr}
        vesselImoErr={vesselImoErr}
        companyID={companyID}
        docData={docData}
      />
    </div>
  );
};
export default VesselList;
