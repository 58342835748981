import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Input,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler, useForm, Controller } from "react-hook-form";
import CustomSelect from "../../common/customSelect";
import FileUpload from "../../common/fileUpload";
import {Spinner} from "@nextui-org/react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  handleSelectBoolean,
  handleFileChange,
  isLoading,
  isModalLoading,
  control,
  code,
  setCode
}) {
  const [data, setData] = useState(editValue);
  
  //useEffect(() => { setVal(editValue);  }, [editValue] )
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal
      size="3xl"
      backdrop="blur"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Company
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3 mt-2">
                      <Input
                        {...register("comp_code")}
                        id="code"
                        type="text"
                        label="Code"
                        labelPlacement="outside"
                        placeholder="Enter Company Code"
                        maxLength="3"
                      />
                      <div className="error-msg">
                        {errors.comp_code?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("email")}
                        id="code"
                        type="text"
                        label="Email"
                        labelPlacement="outside"
                        placeholder="Enter Email"
                      />
                      <div className="error-msg">
                        {errors.email?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <Textarea
                        {...register("address")}
                        variant="faded"
                        label="Address"
                        labelPlacement="outside"
                        placeholder="Enter address"
                        name="address"
                      />
                      <div className="error-msg">
                        {errors.address?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <CustomSelect
                        label="Is parent company"
                        labelPlacement="inside"
                        name="parent_company"
                        register={register}
                        handleSelectBoolean={handleSelectBoolean}
                        handleSelectChange={handleSelectChange}
                        defaultSelectedKeys={[editValue?.parent_company || "NO"]}
                        options="BooleanOptions"
                      />
                    </div>
                    <div className="mb-3 mt-2">
                    <label className="block text-small font-medium pointer-events-none text-foreground pb-1.5 will-change-auto origin-top-left transition-all !duration-200 !ease-out motion-reduce:transition-none">Company Logo</label>
                    <input type="file" accept="image/*"  name="file" onChange={(e)=>{handleFileChange(e.target.files[0])}}  />
                    <div className="error-msg">
                          {errors.file?.message} {errName}
                    </div>
                    </div>
                  </div>

                  <div className="col-md-6">

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("name")}
                        id="name"
                        type="text"
                        label="Company Name"
                        labelPlacement="outside"
                        placeholder="Enter Company Name"
                        name="name"
                      />
                      <div className="error-msg">
                        {errors.name?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                    <label style={{fontSize:'14px'}}>Phone Number</label>
                    <Controller
                      name='phone'
                      control={control}
                      className="form-control"
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            value={editValue?.phone}
                            enableSearch={true}
                            country={code}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />


                      {/* <Input
                        {...register("phone")}
                        id="name"
                        type="text"
                        label="Phone"
                        labelPlacement="outside"
                        placeholder="Enter Phone"
                      /> */}
                      <div className="error-msg">
                        {errors.phone?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <Textarea
                        {...register("description")}
                        variant="faded"
                        label="Description"
                        labelPlacement="outside"
                        placeholder="Enter description"
                        name="description"
                      />
                      <div className="error-msg">
                        {errors.description?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <CustomSelect
                        label="Status"
                        labelPlacement="inside"
                        name="status"
                        register={register}
                        handleSelectBoolean={handleSelectBoolean}
                        handleSelectChange={handleSelectChange}
                        defaultSelectedKeys={[editValue?.status || "ACTIVE"]}
                        options="statusOptions"
                      />
                    </div>

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("short_name")}
                        id="short_name"
                        type="text"
                        label="Short Name"
                        labelPlacement="inside"
                        placeholder="Enter Short Name"
                      />
                      <div className="error-msg">
                        {errors.short_name?.message} {errName}
                      </div>
                    </div>

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                   {isLoading ?<Spinner className="testspinner" color="default" size="md"  />:null}  
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
