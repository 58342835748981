// dateFormatter.js
//October 18th, 2023 at 05:57 PM

export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
  
    return formattedDate.replace(/\d{1,2}/, day + daySuffix);
  }

  
  
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

//03/10/2023, 03:14:57 pm
  export function formatCustomDate(date, options) {
    if (date) {
      return new Date(date).toLocaleDateString('en-IN', options);
    } else {
      return 'Invalid Date';
    }
  }

  export function calculateDaysTillToday(startDate) {
    // Parse the input date into a Date object
    const startDateObj = new Date(startDate);
    
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - startDateObj;
  
    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysDifference;
  }

  export function dateDiffInDays(date1, date2) {
    // Parse the input dates into Date objects
    const date1Obj = new Date(date1);
    const date2Obj = new Date(date2);
  
    // Calculate the time difference in milliseconds
    const timeDifference = date2Obj - date1Obj;
  
    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const roundedDaysDifference = Math.round(daysDifference);
  
    return Math.abs(roundedDaysDifference); // Use Math.abs to get a positive difference
  }


  export function extractDateFromTimestamp(timestampString) {
    const dateObject = new Date(timestampString);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const day = dateObject.getDate();
  
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  }
  
  