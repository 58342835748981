import React, { useEffect, useState } from "react";
import authQueries from "../../queries/authQueries";
import "../../assets/css/tailwind.css";
import { userStore } from '../../store/userStore';

const RankonboardNcodeReport = () => {

      const [result, setResult] = useState([]);
      const companyID = userStore();
      const [loading, setLoading] = useState(true)

      const initCall =  async()=>{
        console.log("companyid is",companyID.reportFilter);
        await mutateAsync({ fromDate:companyID?.reportFilter?.fromDate, toDate:companyID?.reportFilter?.toDate, schema:companyID?.companyData?.company });
        
      }

      useEffect(() => {
         mutateAsync({ fromDate:companyID?.reportFilter?.fromDate, toDate:companyID?.reportFilter?.toDate, schema:companyID?.companyData?.company });
      },[])
      
      useEffect(() => {
        initCall();
      }, [companyID]);

    

      const { mutateAsync } = authQueries.useRankOnboardNocodeReportMutation((response) => {
        setResult(response.data);
        console.log("response data is", response.data);
      })
     
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
    
            
            <div class="row">
                    <div class="col-md-6 text-start p-3 pt-0 pe-2">
                        <h4 className="dashboard-section-title">No Workplace Code Rank On Board Report</h4>
                    </div>
                    <div class="col-md-6 text-end p-3 pt-0 pe-2">
                    
                    </div>
            </div>
              
              <div className="table-wrpr">
                <div className="table-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="100">Rank On Board</th>
                        <th width="100">Total no. of issues</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={index}>
                           <td>{item?.rankonboard}</td>
                           <td>{item?.count}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
             
             
            </div>
          </div>
        </div>
      );

};
export default RankonboardNcodeReport;