import React, { useEffect, useState } from "react";
import Issueview from './issueView';
import Action from './action';
import authQueries from "../../queries/authQueries";
import { useLocation, useParams } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import CheckboxList from '../common/checkbox';
import { useNavigate } from "react-router-dom";
import { message, message as messages, Popconfirm } from "antd";
import RouteConstants from "../../Constants/RouteConstant";
import {Spinner} from "@nextui-org/react";


export default function CompliantView() {
  const [result, setResult] = useState([]);
  const [category, setCategory] = useState([]);
  const companyID = userStore();
  const companyname= companyID?.company?.company?.name;
  const { id } = useParams();
  const [errName, setErrName] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [isAssigningCategory, setIsAssigningCategory] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actioncategory, setActioncategory]=useState("");
  const [users, setUsers] = useState([])
  const [edit, setEdit] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [acceptText, setAcceptText] = useState("")
  const [accepError, setacceptError] = useState("")
  const [mailcontent, setmailcontent] = useState(`Good Day,This email confirms that ${companyname} would like SHIPCONREP to contact the Reporter regarding the following reported issue`);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setAdditionalMsg] = useState("");
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(false);
  const [isOpenstate, setIsOpenstate] = useState(false);
  const [scheduleContent, setscheduleContent] = useState("");
  const [isScheduleLoading, setisScheduleLoading] = useState(false);
  const [datetime, setDatetime] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [ispopconfirm, setIspopconfirm] = useState(true);




  useEffect(() => {
    initCall();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyParams = queryParams.get('company');

  const fullUrl = location.pathname + location.search;
  const searchParams = new URLSearchParams(fullUrl.split('?')[1]);
  let companyIdfromUri = searchParams.get('companyid');
  companyIdfromUri= parseInt(companyIdfromUri)

  const companyView = authQueries.useCompanyMutationView(
    (res)=>{
      const data = res?.data
        companyID.setCompanyData({ company:companyParams  });
        companyID.setCompany({ data  });
    }
  )
  
  const companyGet =async()=> {
    if(companyParams){
      companyView.mutateAsync(companyParams)
    }
  }

  const initCall = async () => {
   
    if(companyIdfromUri && companyID?.user?.role!=='SHIPCONREPADMIN' &&  companyIdfromUri!==companyID?.user?.company){
      messages.error("You don't have permission to view this issue. Redirecting to dashboard.");
      navigate('/dashboard');
    }
    setIsPageLoading(true)
    await companyGet()
    await mutateAsync({ id: id, schema: companyID?.companyData?.company });
    await categoryListing.mutateAsync({ search: "", status: "", page: 0, size: 1000, schema: companyID?.companyData?.company });
  }
  
  useEffect(() => {
    if (!isAssigningCategory) {
      //mutateAsync({ id: id, schema: companyID?.companyData?.company });
      // Add logic for post-assignment actions here
    }
  }, [isAssigningCategory]);

  const handleOpen = () => {
    onOpen();
  }
  
  const { mutateAsync } = authQueries.useCompliantViewtMutation((response) => {
    try {
      accountUserListing.mutateAsync({id:companyID?.user?.id, schema: companyID?.companyData?.company })
      if(response?.message==='Success')
      {
        setResult(response?.data);
        setIsPageLoading(false) 

      }else
      {
        messages.error("You don't have permission to view this issue. Redirecting to dashboard.");
        navigate('/dashboard');
      }

    }catch (error) {

      console.error('Error in handleOpen:', error);
    }


  });

  const categoryListing = authQueries.useCategoryListMutation((response) => {
    const filteredCategory=response?.data?.items?.filter(cate=> cate?.category!=='Others')
    setCategory(filteredCategory);
  });

  const accountUserListing = authQueries.useAccountUserListingDocMutation((response) => {
    // console.log(response)
    setUsers(response?.data);
  });

  const categoryIds = result?.categories?.reduce((accumulator, item) => {
    if(item?.complaint_category?.status!=='INACTIVE'){
      const id = parseInt(item.id);
      accumulator.push(id);
    }
    return accumulator;
  }, []);

  const categoryIdsDisabled = result?.categories?.reduce((accumulator, item) => {
    const id = parseInt(item.id);
    if (item?.complaint_category?.created_by === null) {
      accumulator.push(id);
    }
    return accumulator;
  }, []);

  const assignCategoryApi = async () => {
    setIsAssigningCategory(true);
    const datavalues = {
      compliantID: result.id,
      category: checkboxes,
      schema: companyID?.companyData?.company
    };

    try {
      await assignCategory.mutateAsync(datavalues);
      await mutateAsync({ id: id, schema: companyID?.companyData?.company});
      onClose();
    } catch (error) {
      // Handle the error here, e.g., show an error message to the user.
      console.error('Error in assignCategoryApi:', error);
    } finally {
      setIsAssigningCategory(false);
    }
  };

  const assignCategory = authQueries.useAssignCategoryMutation((response) => {
    if (response?.message === "Success") {
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });


  

  const handleAssignUser = authQueries.useComplaintReassignMutation(
    (res)=> {
      mutateAsync({ id: id, schema: companyID?.companyData?.company }).then(res => {
        setEdit(false)
        setBtnLoader(false)
      })
    }
  )

  const handleAssign =(data)=> {
    handleAssignUser.mutateAsync({data:data,schema: companyID?.companyData?.company})
  }

  const handleAcceptComplaint = authQueries.useComplaintAcceptMutation(
    (res)=>{
      mutateAsync({ id: id, schema: companyID?.companyData?.company})
    }
  )

  const handleAccept =()=> {
    if(acceptText!==''){
      setacceptError("");
      handleAcceptComplaint.mutateAsync({issue: acceptText, complaint_id:result?.id, assignee: companyID?.user?.id})
    }else{
      setacceptError("⚠ Please Name this issue to Accept")
    }
    
  }


  const SendContactInformantmutation = authQueries.useContactInformantMutation((response) => {
    if (response?.message === "Success") {
      mutateAsync({ id: id, schema: companyID?.companyData?.company})
      message.success("Mail Sent Successfully");
    }
  });

  
  const Sendcancellationmutation = authQueries.useSendCancellation((response) => {
    if (response?.message === "Success") {
      mutateAsync({ id: id, schema: companyID?.companyData?.company})
      message.success("Mail Sent Successfully");
    }
  });

  const sendcontactinformantmail = (event) => {
    console.log("inside  function",msg)
    event.preventDefault();
    setIsLoading(true);
    const datavalues = {
      content: mailcontent,
      complaintId: result.id,
      schema:companyID?.companyData?.company,
      amsg: msg
    };
    SendContactInformantmutation.mutateAsync(datavalues).then(() => {
      setIsLoading(false);
    });
  };

  const sendCancellationMail = (event) => {
    event.preventDefault();
    setIsLoading(true);
    Sendcancellationmutation.mutateAsync({complaintId: result.id, schema:companyID?.companyData?.company}).then(() => {
      setIsLoading(false);
    });
  };

  const onSubmitForm1 = (data) => {
    if (schedule) {
      setIsOpenstate(true);
    } else {
      setIsLoading(true);
      const datavalues = {
        content: scheduleContent,
        complaintId:  result.id,
        schema:companyID?.companyData?.company

      };

      SendResponsemutation.mutateAsync(datavalues).then( async () => {
        setIsLoading(false);
        await mutateAsync({ id: id, schema: companyID?.companyData?.company});
      });
    }
  };

  const onSubmitForm2 = (data, event) => {
    setisScheduleLoading(true);
    const datavalues = {
      content: scheduleContent,
      complaintId:  result.id,
      datetime: datetime,
      schema:companyID?.companyData?.company
    };
    setIsOpenstate(false);
    ScheduleLater.mutateAsync(datavalues).then( async () => {
      setisScheduleLoading(false);
      await mutateAsync({ id: id, schema: companyID?.companyData?.company});
    });
  };

  const SendResponsemutation = authQueries.useSendResponseMutation((response) => {
    if (response?.message === "Success") {
      messages.success("Response Mail Sent Successfully");
    } else if (response?.error[0]?.name) {
      setErrName("⚠ " + response?.error[0]?.name);
    }
  });

  const ScheduleLater = authQueries.useScheduleLaterMutation((response) => {
    if (response?.message === "Success") {
      messages.success("Mail Scheduled Successfully");
    } else if (response?.error[0]?.name) {
      setErrName("⚠ " + response?.error[0]?.name);
    }
  });

  

  return (
    <>
    {ispopconfirm ? ( 

                          <Popconfirm
                        placement="left"
                        title="Are you sure?"
                        //onConfirm={(event) => sendcontactinformantmail(event)}
                        okText="Yes"
                        cancelText="No"
                      ></Popconfirm>
    ) : null}

    {isPageLoading ? (
    <div className="loader" >
        <Spinner size="large" />
    </div>
   ) : (
   <div>
      <ul className="breadcrumb">
        <li><a onClick={()=>{navigate(RouteConstants.DASHBOARD) } } href="#"><i className="icon-home4"></i> Home</a></li>
        <li><a href="#"> Issue #{result?.id}</a></li>
      </ul>

      <div className="row issue-details-wrpr" >
        <Issueview
          compliant={result}
          categoryIdsDisabled={categoryIdsDisabled}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          assignCategoryApi={assignCategoryApi}
          handleOpen={handleOpen}
          actioncategory={actioncategory}
          setActioncategory={setActioncategory}
          setAcceptText={setAcceptText}
          acceptText={acceptText}
          handleAccept={handleAccept}
          accepError={accepError}
        />
        {
          result?.complaint_status?.status !== "CLOSED" &&
          <Action 
          compliant={result}
          companyID={companyID}
          users={users}
          handleAssign={handleAssign}
          setEdit={setEdit}
          btnLoader={btnLoader}
          setBtnLoader={setBtnLoader}
          edit={edit}
          mailcontent={mailcontent} 
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          sendcontactinformantmail={sendcontactinformantmail}
          setAdditionalMsg={setAdditionalMsg}
          onSubmitForm1={onSubmitForm1}
          setSchedule={setSchedule}
          isOpenstate={isOpenstate}
          setIsOpenstate={setIsOpenstate}
          setscheduleContent={setscheduleContent}
          isScheduleLoading={isScheduleLoading}
          onSubmitForm2={onSubmitForm2}
          setDatetime={setDatetime}
          datetime={datetime}
          sendCancellationMail={sendCancellationMail}
         
          />
        }
      </div>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        backdrop='blur'
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Assign New Category</ModalHeader>
              <ModalBody>
                <div className='custom-check'>
                  <CheckboxList
                    data={category}
                    selectedIds={categoryIds}
                    categoryIdsDisabled={categoryIdsDisabled}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={assignCategoryApi}>
                  Assign
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      </div>
      )}
      
    </>
  );
}
