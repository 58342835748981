import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Button, useDisclosure,  } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import LoaderScreen from "../../common/LoaderScreen";
const validationSchema = yup.object({
  comp_code: yup
    .string("⚠ Enter Company Code")
    .required("⚠ Company Code is required")
    .min(2, "⚠ Company Code must be more than 2 characters "),
    name: yup
    .string("⚠ Enter Company Name")
    .required("⚠ Company Name is required")
    .min(2, "⚠Company Name must be more than 2 characters "),
    file: yup
    .string("⚠ please select company logo")

});

const CompanyList = () => {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [booleanSelect, setbooleanSelect] = useState("NO");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [image, setImage] = useState('');
  const [imageData, setimageData] = useState();
  const [loading, setLoading] = useState(true)
  const [code, setCode] = useState('in')

  useEffect(() => {
    mutateAsync({ search: "", status: "", page: 0, size: 10 });
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useCompanycountListMutation((response) => {
    setResult(response?.data);
    setLoading(false)
  });

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSelectBoolean = (event) => {
    const newValue = event.target.value;
    setbooleanSelect(newValue);
  };

  const setInitialValue = (data) => {
    setEditValue(data?.company);
    let datas = {
      id: data?.company?.id,
      comp_code: data?.company?.comp_code,
      name:data?.company?.name,
      email:data?.company?.email,
      phone:data?.company?.phone,
      address:data?.company?.address,
      short_name:data?.company?.short_name,
      parent_company:booleanSelect,
      description:data?.company?.description,
      status: selectedValue,
    };
    setSelectedValue(data.status)
    setbooleanSelect(data.parent_company)
    reset({ ...datas });
    onOpen(true);
  };

  const AddData = authQueries.useCompanyAddMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      setimageData()
      mutateAsync({ search: "", status: "", page: 0, size: 10 })
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useCompanyUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      setimageData()
      mutateAsync({ search: "", status: "", page: 0, size: 10 });
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useCompanyMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 });
    messages.success("successfully deleted");
  });

  const handleFileChange =(e)=>{
    setimageData(e)
  }

const imageUpload = authQueries.useImageUploadSingleMutation(
  (response) => {
      setImage(response?.file?.filename)
  }   
);

  const onSubmit = async(data) => {
    const datavalues = {
      comp_code: data.comp_code,
      name:data.name,
      email:data?.email,
      phone:data?.phone,
      address:data.address,
      short_name:data.short_name,
      parent_company:booleanSelect,
      description:data.description,
      status: selectedValue,
      url: ''
    };
    if (!editValue) {
      
      if(imageData){
        const fileData = new FormData();
        fileData.append('file', imageData)
        await imageUpload.mutateAsync(fileData).then(res=> {
          datavalues.url = res?.file?.filename
        })
      }

      AddData.mutateAsync(datavalues).then(() => {
        let datas = {
          comp_code: "",
          name:"",
          email:"",
          phone:"",
          address:"",
          short_name:"",
          description:"",
          parent_company:"NO",
          status: "ACTIVE",
        };
        setSelectedValue("ACTIVE")
        setbooleanSelect("NO")
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        comp_code: data.comp_code,
        name:data.name,
        email:data?.email,
        phone:data?.phone,
        address:data.address,
        short_name:data.short_name,
        parent_company:booleanSelect,
        description:data.description,
        url: data.url,
        status: selectedValue,
      };
      if(imageData){
        const fileData = new FormData();
        fileData.append('file', imageData)
        await imageUpload.mutateAsync(fileData).then(res=> {
          datavalues.url = res?.file?.filename
        })
      }
      updateData.mutateAsync(datavalues).then(() => {

        let datas = {
          comp_code: "",
          name:"",
          email:"",
          phone:"",
          address:"",
          short_name:"",
          description:"",
          parent_company:"NO",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    console.log("compnayid",id)
    event.preventDefault();
    deleteData.mutateAsync(id);
  };

  const addButton = ()=>{
    setEditValue(null);
    onOpen(true);
    let datas = {
      comp_code: "",
      name:"",
      email:"",
      phone:"",
      address:"",
      short_name:"",
      description:"",
      parent_company:"NO",
    };
    reset({ ...datas });
  }

  return (
    <div className="">
      <div className="row">
        {
          loading ? 
          <LoaderScreen />:
          <div className="col-md-12">
            <div className="text-end p-3">
              {/* <Button
                color="primary"
                className="btn btn-add"
                onPress={addButton}
              >
                <i className="icon-add"></i> Add
              </Button> */}
            </div>
            <div className="table-wrpr">
              <div className="table-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th width="20">#</th>
                      <th width="60">Code</th>
                      <th width="200">Name</th>
                      <th width="80">Short Name</th>
                      <th width="200">Address</th>
                      <th width="250">Description</th>
                      <th width="60">Status</th>
                      <th width="120">Parent Company</th>
                      <th width="80">Logo</th>
                      <th width="50">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.items?.map((item, index) => (
                      <tr key={index}>
                        <td>{(index + 1) + result?.currentPage * 10}</td>
                        <td>{item?.company?.comp_code}</td>
                        <td>{item?.company?.name}</td>
                        <td>{item?.company?.short_name}</td>
                        <td>{item?.company?.address}</td>
                        <td>{item?.company?.description}</td>
                        <td>{item?.company?.status}</td>
                        <td>{item?.company?.parent_company}</td>
                        <td>
                          {
                            item?.company?.url ?
                            <img className="logo-company" src={`${process.env.REACT_APP_BASE_URL}/api/fileupload/${item?.company?.url}`} /> :
                            <Avatar name={item?.company?.short_name} />
                          }
                        </td>
                        <td className="action-links">
                          <div className="flex gap-2">
                            <a href="#"
                              onClick={() => setInitialValue(item)}
                              className="btn btn-view"
                            >
                              <i className="icon-edit"></i>
                            </a>

                            <Popconfirm
                              placement="left"
                              title="Are you sure to delete this ?"
                              onConfirm={(event) => {
                                deleteEvent(event, item?.company?.id);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button className="btn btn-view"> <i className="icon-delete"></i></button>
                            </Popconfirm>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination-wrpr">
              <Pagination  
              showControls
              total={result?.totalPages} 
              onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
              initialPage={1}  
              />
            </div>
          </div>
        }
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        register={register}
        errors={errors}
        errName={errName}
        handleSelectChange={handleSelectChange}
        handleSelectBoolean={handleSelectBoolean}
        handleFileChange={handleFileChange}
        control={control}
        code={code}
        setCode={setCode}
      />
    </div>
  );
};
export default CompanyList;
