import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../Constants/RouteConstant";
import authQueries from "../../queries/authQueries";
import { Pagination } from '@nextui-org/react';
import LoaderScreen from "../common/LoaderScreen";
import { Button, useDisclosure } from "@nextui-org/react";

const Profile = () =>{

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        mutateAsync({ search: "", status: "", page: 0, size: 10 });
      }, []);

      const { mutateAsync } = authQueries.useProfileList((response) => {
        setResult(response.data);
        setLoading(false)
      });


      return (
        <div className="container">
          <div className="row">
            {
              loading ?
              <LoaderScreen />:
              <div className="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-end p-3 pt-0">
                            
                        </div>
                    </div>
                </div>
                <div className="text-end p-3">
                </div>
                <div className="table-wrpr">
                  <div className="table-section demo-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="50">#</th>
                          <th width="50">Age</th>
                          <th width="100">Shiptype</th>
                          <th width="100">Gender</th>
                          <th width="50">Rank on board</th>
                          <th width="50">Platform</th>
                        </tr>
                      </thead>
                      <tbody>

                        {result?.items?.map((item, index) => (
                          <tr key={index}>
                            <td>{(index + 1) + result?.currentPage * 10}</td>
                            <td>{item?.age==1 ? '18-25' : item?.age == 2 ? '26-30' : item?.age == 3 ? '31-35' : item?.age == 4 ? '36-40' : item?.age == 5 ? '41-45' : item?.age == 6 ? '46-50' : item?.age == 7 ? '51-55' : item?.age == 8 ? '56-60' : item?.age == 9 ? '60-Above' : ''}</td>
                            <td>{item?.shiptype === 19 ? item?.ship_other : item?.ship_type?.type}</td>
                            <td>{item?.gender}</td>
                            <td>{item?.rankonboard === 26 ? item?.rank_other : item?.rank?.name}</td>
                            <td>{item?.platform}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pagination-wrpr">
                  <Pagination  
                  showControls
                  total={result?.totalPages} 
                  onChange={(e) => {mutateAsync({search: '', page: e-1 , size:pageSize},setCurrentPage(e-1))}}
                  initialPage={1}  
                  />
                </div>
              </div>
            }
          </div>
        </div>
      );

}

export default Profile
