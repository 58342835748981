import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import {Spinner} from "@nextui-org/react";
import RouteConstants from "../../../Constants/RouteConstant";

const validationSchema = yup.object({
  name: yup
    .string("⚠ Enter Fleet Name")
    .required("⚠ Fleet Name is required")
    .min(2, "⚠ Fleet Name must be more than 2 characters "),
  doc_holder_id: yup
    .string("⚠ Select Doc Holder")
    .required("⚠ Doc Holder is required"),
  contact_email: yup
    .string("⚠ Enter a valid email")
    .email("⚠ Enter a valid email address")
});

const FleetList = () => {
  const [result, setResult] = useState([]);
  const [docholder, setHocholder] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [countrySelect, setCountrySelect] = useState("");
  const companyID = userStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isbuttonLoading, setIsbuttonLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const InitCall =async ()=> {
    let FilterValues={
      selectedDoc:'',
      selectedVessel:'',
      selectedAccntStatus:'',
      selectedCategory:'',
      selectedPlatform:'',
      selectedReference:'',
      selectedVesselKey:'',
      selectedFleet:'',
      selectedDocKey:'',
      selectedFleetkey:''     
}
      companyID.setSelectedFilter(FilterValues);
    setIsLoading(true); 
    mutateAsync({ search: "", status: "", doc:"", page: 0, size: 10, schema: companyID?.companyData?.company })
      .then(() => {
        setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false); 
      });
}

  useEffect(() => {
    InitCall();
  }, []);



  useEffect(() => {
    mutateAsync({ search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page: 0, size: 10, schema: companyID?.companyData?.company })
    .then(() => {
      setIsLoading(false); 
    })
    .catch(() => {
      setIsLoading(false); 
    });
  }, [companyID]);

  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useFleetListMutation((response) => {
    setResult(response.data);
  });

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSelectDoc = (event) => {
    const newValue = event.target.value;
    setValue("doc_holder_id", newValue);
  };

  const docListing = authQueries.useDocListMutation(
    (response) => {
      setHocholder(response.data?.items);
    }
  );

  const setInitialValue = (data) => {
     setIsModalLoading(true); 
     docListing.mutateAsync({search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page:0, size:1000, schema:companyID?.companyData?.company})
      .then(() => {
        setIsModalLoading(false); 
      })
      .catch(() => {
        setIsModalLoading(false); 
      });
    
    setEditValue(data);
    let datas = {
      id: data.id,
      doc_holder_id: data.doc_holder?.id,
      name:data.name,
      contact_person:data.contact_person,
      contact_email:data.contact_email,
      telephone:data.telephone,
      status: data.status,
      schema:companyID?.companyData?.company
    };
    console.log("initivalues==>",data)
    setSelectedValue(data.status)
    reset({ ...datas });
    onOpen(true);
  };

  const AddData = authQueries.useFleetAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.FLEET);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useFleetUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page: 0, size: 10, schema:companyID?.companyData?.company});
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useFleetMutationDelete((response) => {
    mutateAsync({ search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc,  status: "ACTIVE", page: 0, size: 10, schema:companyID?.companyData?.company });
    messages.success("successfully deleted");
  });

  const onSubmit = (data) => {
    setIsbuttonLoading(true);
    const datavalues = {
      doc_holder_id: data.doc_holder_id,
      name:data.name,
      contact_person:data.contact_person,
      contact_email:data.contact_email,
      telephone:data.telephone,
      status: selectedValue,
      schema:companyID?.companyData?.company
    };
    console.log("datavalues",datavalues)
    if (!editValue) {
      let datas = {
        name:"",
        contact_person:"",
        contact_email:"",
        telephone:""
      };
      AddData.mutateAsync(datavalues).then(() => {
        mutateAsync({ search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page: 0, size: 10, schema:companyID?.companyData?.company })
        .then(() => {
          setIsbuttonLoading(false); 
          onOpenChange(false);
          setSelectedValue("ACTIVE")
          reset({ ...datas });
        })
        .catch(() => {
          setIsbuttonLoading(false); 
          onOpenChange(false);
          setSelectedValue("ACTIVE")
          reset({ ...datas });
        });

       
      });
    } else {
      const datavalues = {
        id: editValue.id,
        doc_holder_id: data.doc_holder_id,
        name:data.name,
        contact_person:data.contact_person,
        contact_email:data.contact_email,
        telephone:data.telephone,
        status: selectedValue,
        schema:companyID?.companyData?.company
      };
      updateData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false); 
        let datas = {
          name:"",
          contact_person:"",
          contact_email:"",
          telephone:""
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    event.preventDefault();
    deleteData.mutateAsync({id:id,schema:companyID?.companyData?.company});
  };

  const addButton = ()=>{
    setEditValue(null);
    let datas = {
      name:"",
      contact_person:"",
      contact_email:"",
      telephone:""
    };
    reset({ ...datas });
    onOpen(true);
    setIsModalLoading(true); 
    docListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:companyID?.companyData?.company})
     .then(() => {
       setIsModalLoading(false); 
      })
     .catch(() => {
       setIsModalLoading(false); 
      });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Fleet </h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
            <Button
              color="primary"
              className="btn btn-add"
              onPress={addButton}
            >
              <i className="icon-add"></i> Add
            </Button>
          </div>
          {isLoading ? (
          <div className="loader" >
            <Spinner size="large" />
          </div>
           ) : (
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Doc Holder</th>
                    <th width="100">Fleet Name</th>
                    <th width="100">Contact Person</th>
                    <th width="100">Contact Email</th>
                    <th width="100">Phone</th>
                    <th width="100">Status</th>
                    <th width="100">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item?.doc_holder?.name}</td>
                      <td>{item?.name}</td>
                      <td>{item?.contact_person}</td>
                      <td>{item?.contact_email}</td>
                      <td>{item?.telephone}</td>
                      <td>
                      <span className={`status ${item.status === 'ACTIVE' ? 'open' : 'inactive'}`}>{item.status}</span>
                      </td>
                      <td className="action-links">
                      <a href="#"
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                           <i className="icon-edit"></i>
                        </a>
                      {companyID?.user?.role==='SHIPCONREPADMIN'?
                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this ?"
                          onConfirm={(event) => {
                            deleteEvent(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view"> <i className="icon-delete"></i></button>
                        </Popconfirm>:null
                      }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          )}
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => { 
              setIsLoading(true); 
              mutateAsync({search: companyID?.companyFilter?.selectedFleetkey, doc: companyID?.companyFilter?.selectedDoc, status: companyID?.companyFilter?.selectedAccntStatus, page: e-1 , size:pageSize, schema:companyID?.companyData?.company },
              setCurrentPage(e-1)).then(()=>{
              setIsLoading(false); 
              })
            }}
            initialPage={1}  
            />
          </div>
        </div>
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
        handleSelectDoc={handleSelectDoc}
        docholder={docholder}
        isLoading={isbuttonLoading}
        isModalLoading={isModalLoading}
        companyID={companyID}
        control={control}
      />
    </div>
  );
};
export default FleetList;
