import React from "react";
import AppRoutes from './Routes/AppRoute';
import { StoreProvider } from './context/context'; 

function App() {
  return (
    <div>
       <StoreProvider>
         <AppRoutes/>
       </StoreProvider>
    
    </div>
  );
}

export default App;
