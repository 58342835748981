import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Spinner, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import LoaderScreen from "../../common/LoaderScreen";
import RouteConstants from "../../../Constants/RouteConstant";


const validationSchema = yup.object({
  type: yup
    .string("⚠ Enter Ship Type")
    .required("⚠ Ship Type is required")
    .min(2, "⚠ Ship Type must be more than 2 characters "),
});

const ShipTypeList = ({ selectedStatus }) => {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [companySelect, setcompanySelect] = useState("");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const companyID = userStore();
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    mutateAsync({ search: "", status: "", page: 0, size: 10, schema:companyID?.companyData?.company });
  }, [selectedStatus]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useShipTypeListMutation((response) => {
    setResult(response?.data);
    setLoading(false)
  });


  const modalOpen = () => {
    let datas = {
      type: "",
      status: "ACTIVE",
    };
    reset({ ...datas });
    onOpen(true);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };
  const handleSelectCompany = (event) => {
    const newValue = event.target.value;
    setcompanySelect(newValue);
  };
    let datas = {
      type: "",
      status: "ACTIVE",
    };
  const setInitialValue = (data) => {
    setEditValue(data);
    let datas = {
      id: data.id,
      type: data.type,
      status: data.status,
    };
    setSelectedValue(data.status)
    reset({ ...datas });
    onOpen(true);
  };

  const AddShipdata = authQueries.useShipTypeAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.SHIPTYPELIST);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateShipdata = authQueries.useShipTypeUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: "", status: "", page: 0, size: 10 });
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteShipData = authQueries.useShipTypeMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 });
    messages.success("successfully deleted");
  });

  const onSubmit = (data) => {
    const datavalues = {
      type: data.type,
      status: selectedValue,
      schema:companyID?.companyData?.company
    };
    if (!editValue) {
      AddShipdata.mutateAsync(datavalues).then(() => {
        onOpenChange(false);
        mutateAsync({ search: "", status: "", page: 0, size: 10, schema:companyID?.companyData?.company });
        let datas = {
          type: "",
          status: "ACTIVE",
        };
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        type: data.type,
        status: selectedValue,
        schema:companyID?.companyData?.company
      };
      updateShipdata.mutateAsync(datavalues).then(() => {
        let datas = {
          type: "",
          status: "ACTIVE",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteShipTypeData = (event, id) => {
    event.preventDefault();
    deleteShipData.mutateAsync(id);
  };

  


  return (
    
    <div className="container">
      <div className="row">
        {loading ?
        <LoaderScreen />:
        <div className="col-md-12">
           <div class="row">
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
            <Button
              color="primary"
              className="btn btn-add"
              onPress={() => {
                modalOpen()
              }}
            >
              <i className="icon-add"></i> Add
            </Button>
          </div>
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Ship Type</th>
                    <th width="250">Status</th>
                    <th width="50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item.type}</td>
                      <td>
                        <span className="status open">{item.status}</span>
                      </td>
                      <td className="action-links">
                        <a
                          href="#"
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                          Edit
                        </a>

                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this Ship type ?"
                          onConfirm={(event) => {
                            deleteShipTypeData(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view">Delete</button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
        }
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
      />
    </div>
  );
};
export default ShipTypeList;
