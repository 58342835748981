import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useNavigate } from "react-router-dom";
import RouteConstants from "../Constants/RouteConstant";
import Contact from "./contactForm";


const About = () =>{

    const [previousScroll, setPreviousScroll] = useState(0);
    const navigate = useNavigate();
    const [isModalopen, setisModalopen]=useState(false);
    

    const handleScroll = () => {

        const currentScroll = window.scrollY;

        if (currentScroll > 480) {
            NavTrans();
        } else {
            removeNavTrans();
        }

        if (currentScroll < 80) {
            showTopNav();
        } else if (currentScroll > 0 && currentScroll < document.body.scrollHeight - window.innerHeight) {
            if (currentScroll > previousScroll) {
                hideNav();
            } else {
                showNav();
            }
            setPreviousScroll(currentScroll);
        }
    };


	const hideNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-visible');
            navbar.classList.add('is-hidden');
        }
    };

    const showNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden');
            navbar.classList.add('is-visible', 'scrolling');
        }
    };

    const showTopNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden', 'scrolling');
            navbar.classList.add('is-visible');
        }
    };

    const NavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.add('whiteNav');
        }
    };

    const removeNavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('whiteNav');
        }
    };


	useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScroll]);


    useEffect(() => {
        AOS.init();
      }, []);



    return ( 
            <>
                <nav id="navbar" class="navbar navbar-expand-md ml-3 mr-3  navbar-light  is-visible  fixed-top ">
                    <div class="navbar-brand   order-1 order-md-0 dual-collapse2">
                        <a onClick={()=>{navigate(RouteConstants.ROOT)}} class="navbar-brand mr-auto" >
                            
                            <img class="logo"  src={require("../assets/images/site/logo.png")} />
                        </a>
                        
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">
                            <span class="navbar-toggler-icon">
                            <span></span>
                            </span>
                        </button>
                    
                    </div>
                    <div class="navbar-collapse  mx-auto order-0 header-center-nav" id="collapsingNavbar">
                        <ul class="navbar-nav me-auto">
                        </ul>
                        <ul class="navbar-nav ms-auto">
                            
                            <li class="nav-item">
                                <a  class="nav-link employer-login" href="/login">Employer Login</a>
                            </li>
                            
                        </ul>
                    </div>
                    
                </nav>
                    
                <header class="masthead about-us faq">
                    <div class="overlay"></div>
                    <div class="container h-100">
                        <div class="row h-100 text-center">
                            <div class="col-12 bnr-top-pding ">
                                <h1 data-aos="fade-down" >About Us</h1>
                            </div>
                        </div>
                    </div>
                </header>

                <div class="container aboutus-wrpr" style={{maxWidth:'1320px'}} data-aos="fade-down" data-aos-delay="500">
                    <div class="row">
                    <div className="col-md-12">
            <p className="paragraph">SHIPCONREP developed this online portal to create an avenue for sea-staff and shore staff to report any issues that affect their ability to carry out their responsibilities confidentially. SHIPCONREP creates the opportunity to improve the workplace and for all staff to feel valued.</p>

            <p className="paragraph">We are a group of ex-seafarers who are passionate about continuous improvement in the Shipping industry, focusing on its primary resource: Sea-staff and the Shore-Staff.</p>

            <p className="paragraph">We offer an Ombudsperson service to Shipping companies that subscribe to this service. This ombudsman service will include:</p>

            <ul>
                <li><b>•</b> Examining complaints raised by sea-staff or shore staff to find a fair and equitable resolution.</li>
                <li><b>•</b> The Ombudsperson service will remain fiercely independent and impartial – the Ombudsperson will not take sides.</li>
                <li><b>•</b> The Ombudsperson will only intervene if the complaint cannot be resolved directly with the organisation.</li>
            </ul>

            <p className="paragraph">If you require more information, please write to <a href="mailto:Info@shipconrep.com">Info@shipconrep.com</a></p>
        </div>
                    </div> 
                </div>

                <footer className="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-2">
                                {/* <img src={require("../assets/images/site/Ship-icon-rep-logo.png")} /> */}
                            </div>
                            <div class="col-md-10 bottom-border">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Shipconrep</h5>
                                        <div class="anchors">
                                        <a  href={RouteConstants.ABOUT}>About Us</a>
                                        <a  href={RouteConstants.FAQ}>FAQs</a>
                                        <a  href={RouteConstants.PRIVACYPOLICY} >Privacy Policy</a>
                                        <a className="contactUs" onClick={ ()=>{setisModalopen(true)}}>Contact Us</a>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>Services</h5>
                                        <div class="anchors">
                                        <a href="/frontend">Web Hotline</a>
							            <a href="/login">Employer Portal</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <h5>Email ID</h5>
                                        <div class="anchors">
                                            <a href="#">info@shipconrep.com</a>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <hr class="border-white" />
                                    </div>
                                    
                                    <div class="col-md-12 text-center pt-3 pb-5">
                                    <h4>This portal is administered by:</h4>
                                        <p>Shipping Industry Confidential Reporting Limited. Registered in England and Wales: 13395969</p>
                                    </div>
                                    <div class="col-md-12">
					<div class="anchors" style={{display:'flex'}}>
					  <a  href="https://www.facebook.com/people/Shipping-Industry-Confidential-Reporting/100094405543592/"><img className="fbicon" src={require("../assets/images/site/fb.jpg")} /></a>
					  <a  href="https://www.linkedin.com/company/shipping-industry-confidential-reporting-pvt-ltd"><img className="fbicon" src={require("../assets/images/site/linkedin.png")} /></a>
					  </div>
					</div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </footer>

                <Contact isModalopen={isModalopen} setisModalopen={setisModalopen} />  
      </>
    )
}

export default About;