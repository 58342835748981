
import  create  from "zustand";
import { persist } from "zustand/middleware";


export const userStore = create(
    persist((set) => ({
      user: {
          userId: "",
          accessToken: "",
          
      },
      setUser: (userDetails) => {
        set({ user: userDetails });
      },
      setCompanyData: (companyDetails) => {
        set({ companyData: companyDetails });
      },
      setRerender: (data) => {
        set({ companyData: data });
      },
      setSelectedFilter: (filter) => {
        set({ companyFilter: filter });
      },
      setSideBarActiveTab: (data) => {
        set({ activeTab: data });
      },
      setCompany: (company) => {
        set({ company });
      },
      setReportFilter: (filter) => {
        set({ reportFilter: filter });
      },
      setReportFilterOn: (val) => {
        set({ status: val });
      },
     
    }))
  );