import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import CustomSelect from "../../common/customSelect";

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
}) {
  //const [data, setData] = useState(editValue);
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  // };

  return (
    <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
              {editValue ? "Update Country" : " Add Country"}
              </ModalHeader>
              <ModalBody>
                <div class="mb-3 mt-2">
                  <Input
                    {...register("country")}
                    id="country"
                    type="text"
                    label="country"
                    labelPlacement="outside"
                    placeholder="Enter your Country"
                  />
                  <div className="error-msg">
                    {errors.country?.message} {errName}
                  </div>
                </div>

                <div class="mb-3 mt-2">
                  <CustomSelect name="status" register={register} handleSelectChange ={handleSelectChange} defaultSelectedKeys={[editValue?.status || "ACTIVE"]} options="statusOptions" />
                </div>

              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
