import authQueries from "../../queries/authQueries";
import React, { useState, useEffect } from 'react';
import { message, message as messages, Popconfirm } from "antd";
import { red } from "@mui/material/colors";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatTimestamp, formatCustomDate, calculateDaysTillToday } from '../../helpers/dateFormatter';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Select,
  SelectItem
} from "@nextui-org/react";
import DateTimePicker from '../common/datepicker.tsx';
import { userStore } from '../../store/userStore';
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../Constants/RouteConstant.js";

// Validation schema for the contact informant form
const validationSchemaForm1 = yup.object({
  sendresponse: yup
    .string("⚠ Enter Message Body")
    .required("⚠ Message Body is required"),
});

// Validation schema for the schedule form
const validationSchemaForm2 = yup.object({
  datetime: yup
    .string("⚠ Select Date Time")
    .required("⚠ Date Time is required"),
});

export default function Action({ 
  compliant , 
  companyID, 
  users, 
  handleAssign, 
  edit, setEdit, 
  btnLoader, setBtnLoader, 
  mailcontent, isLoading, 
  sendcontactinformantmail, 
  setIsLoading,
  setAdditionalMsg, 
  onSubmitForm1,
  setSchedule,
  isOpenstate,
  setIsOpenstate,
  setscheduleContent,
  isScheduleLoading,
  onSubmitForm2,
  setDatetime,
  datetime,
  sendCancellationMail
}) {

  const navigate = useNavigate()
  const [errName, setErrName] = useState("");
  const [err, setErr] = useState("please enter the message body");
  const [response, setResponse] = useState([]);

  const [sendresponse, setsendresponse] = useState("");


console.log("companyID?.company?",companyID?.company)

  const [issueClosed, setIssueClosed] = useState('NO');
  const [lessonLearned, setLessonlearned] = useState('');
  const [isLessonLoading, setisLessonLoading] = useState(false);
  const [issueclosedErr, setissueclosedErr] = useState(false);
  const [howissueclosed, setHowissueclosed] = useState("");
  const [assignee, setAssignee] = useState({id: null, name: ''})
  const [newAssignee, setNewAssignee] = useState(null)
  const schemaData = userStore();
  
  useEffect(()=> {

    if(compliant?.complaint_assigns?.length > 0){
      const user = compliant?.complaint_assigns?.find(item=> (item.status === "ACTIVE" && item?.app_user?.user_role?.roleid === 4))
      if(user){
        setAssignee({id: user?.assignee, name: `${user?.app_user?.fname} ${user?.app_user?.lname}`})
      }
    }

  },[compliant?.complaint_assigns])

  const handleDateChange = (value, dateString) => {
  };

  const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	  }

  const handleOk = (value) => {
    setDatetime(value);
    setValue("datetime", value);
  };

  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    control: controlForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    resolver: yupResolver(validationSchemaForm1),
  });

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    control: controlForm2,
    setValue,
    formState: { errors: errorsForm2 },
  } = useForm({
    resolver: yupResolver(validationSchemaForm2),
  });


  const Lesson = authQueries.useLessonMutation((response) => {
    if (response?.message === "Success") {
      messages.success("Submitted Successfully");
      navigate(RouteConstants.DASHBOARD)
    } else if (response?.error[0]?.name) {
      setErrName("⚠ " + response?.error[0]?.name);
    }
  });

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIssueClosed('YES');
      setissueclosedErr(true);
    } else {
      setIssueClosed('NO');
      setissueclosedErr(false);
    }
    console.log("setIssueClosed", event.target.checked);
  };

  const lessonSubmit = () => {
    if(issueClosed==='YES' && howissueclosed===''){
      setissueclosedErr(true);
      return
    }else{
      setissueclosedErr(false);
    }
    setisLessonLoading(true);
    const datavalues = {
      complaintId: compliant.id,
      lesson_learned: lessonLearned,
      is_issue_closed: issueClosed,
      howissueclosed:howissueclosed,
      schema:schemaData?.companyData?.company
    };
    Lesson.mutateAsync(datavalues).then(() => {
      setisLessonLoading(false);
    });
  };




  const handleSelectUser =(e)=> {
    setNewAssignee(e?.target?.value)
  }
  
  const handleSubmit =async()=> {
    setBtnLoader(true)
    if(newAssignee === null){
      return message.error("Please select one user")
    }
    await handleAssign({assignee: newAssignee, complaint_id: compliant?.id, old_assignee: assignee?.id, complaint: compliant})
    setNewAssignee(null)
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const isActive = compliant?.is_contact_informant === 1;
  const className = `tab-pane ${isActive ? 'active' : ''}`;
  const classNameSec = `tab-pane ${isActive ? '' : 'active'}`;
  const displayVal = `${isActive ? 'block' : 'none'}`;
console.log("compliant?.complaint_assigns",compliant?.complaint_assigns)

console.log("schemaData",schemaData)
  return (
    <>
      <div className="col-md-6">
        <h5>Action & Response</h5>

        <div className="card">
          <div className="card-body">

            <div className="issue-response-form">
              <label>Account User Assigned</label>
              <div>
              {
                !edit ?
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="User Assigned" value={assignee?.name} />
                  {(companyID?.user?.role=== 'SUPERUSER' && users.length>1) &&
                    <div className="input-group-append">
                      <button title="Click here if you want to change Account user" className="btn btn-edit text-white" type="submit" onClick={()=> {setEdit(true)}}><i className="icon-pencil3"></i></button>
                    </div>
                  }
                </div>:
                <div className="flex items-center gap-4 mb-3 mt-2">
                  {users?.length > 0 && 
                  <Select
                      labelPlacement="outside"
                      placeholder="Account User Assigned"
                      onChange={(e) => handleSelectUser(e)}
                  >
                      {users?.map((option) => (
                        (option?.id !== assignee?.id) &&
                        <SelectItem key={option.id} value={option.id}>
                          {option.name}
                        </SelectItem>
                      ))}
                  </Select>
                  }
                  <div className="input-group-append">
                      <button disabled={btnLoader} className="btn btn-edit text-white" type="submit" onClick={handleSubmit}><i className="icon-tick"></i></button>
                  </div>
                </div>
              }
              </div>
            </div>

            <h6 className="blue-label">Does {capitalizeFirstLetter(companyID?.company?.company?.short_name.toLowerCase())}  want SHIPCONREP to</h6>
            <ul className="nav nav-tabs">
              <li className="nav-item"  style={{ display: displayVal }} >
                <a className="nav-link active" data-bs-toggle="tab" href="#contactinformant"><span>SHIPCONREP</span><br />Contact the Reporter</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#sendresponse"><span>&nbsp;</span><br />Send a Response to the Reporter</a>
              </li>
            </ul>

            <div className="tab-content">
              <div   className={className} id="contactinformant">
                <div className="col-md-12 sendresponse-form">
                  <textarea disabled className="form-control" value={mailcontent} placeholder="Enter the message">
                  </textarea>
                  <div className="additional-message-cntnr mt-3">
                    {compliant?.shipconrep_contact_informant==='YES'?
                    <p >The Above confirmation Mail sent to Shipconrep on { formatCustomDate(compliant?.shipconrep_contact_informant_at, options)} </p>:null}
                    {compliant?.callback === 'YES' && compliant?.shipconrep_contact_informant==='NO' ?
                      <div className="custom-check-wrpr">
                        <div className="custom-control custom-checkbox text-end">
                        
                          <input type="checkbox" className="custom-control-input" id="additionalmsg" name="additionalmsg" />
                         
                          <textarea onChange={(e)=>{setAdditionalMsg(e.target.value)}} className="form-control d-none" placeholder="Enter the message"></textarea>
                          <label className="custom-control-label" htmlFor="additionalmsg">Tick here to add additional message to SHIPCONREP</label>
                        </div>
                      </div> : null
                    }
                  </div>
                  <div className="text-end">

                      {compliant?.shipconrep_contact_informant==='NO'?
                      <Popconfirm
                        placement="left"
                        title="Are you sure?"
                        onConfirm={(event) => sendcontactinformantmail(event)}
                        okText="Yes"
                        cancelText="No"
                      >
                      <Button 
                      disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? true: false}
                      className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}
                      color="primary" 
                      size="sm" 
                      radius="full" >
                        {isLoading ? <Spinner className="testspinner" color="default" size="md" /> : null}
                        Send Confirmation
                      </Button>
                      </Popconfirm>:
                      <Popconfirm
                        placement="left"
                        title="Are you sure want to send cancellation"
                        onConfirm={(event) => sendCancellationMail(event)}
                        okText="Yes"
                        cancelText="No"
                    >
                      <Button 
                        isDisabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? true: false}
                        className= "bg-primary"
                        color="primary" 
                        size="sm" 
                        radius="full" >
                        {isLoading ? <Spinner className="testspinner" color="default" size="md" /> : null}
                        Send Cancellation
                      </Button>
                    </Popconfirm>
}
                  </div>
                </div>
              </div>
              <div className={classNameSec} id="sendresponse">
                <form onSubmit={handleSubmitForm1(onSubmitForm1)}>
                  <div className="col-md-12 sendresponse-form">
                    <textarea name="sendresponse" maxLength={5000} {...registerForm1("sendresponse")} onChange={(e) => setscheduleContent(e.target.value)} className="form-control" placeholder="Enter below to send response to the Repoter" ></textarea>
                    <div className="mt-2">
                    {compliant?.email_schedule?.mail_schedule_time && (
                      <>The above response scheduled to send to the Reporter on {formatCustomDate(compliant.email_schedule.mail_schedule_time, options)}</>
                    )}
                  </div>

                    <div className="error-msg">
                      {errorsForm1.sendresponse?.message} {errName}
                    </div>
                    <div className="text-end">
                      <Button 
                        type="submit" 
                        onPress={() => { setSchedule(false) }} 
                        disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? true: false}
                        className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}
                        color="primary" size="sm" radius="full" 
                      >
                        {isLoading ? <Spinner className="testspinner" color="default" size="md" /> : null}
                        Send
                      </Button>
                      <Button 
                        type="submit" 
                        onPress={() => { setSchedule(true) }} 
                        disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? true: false}
                        className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}
                        color="primary" 
                        size="sm" radius="full"
                       >
                        {isScheduleLoading ? <Spinner className="testspinner" color="default" size="md" /> : null}
                        Schedule later
                      </Button>
                    </div>
                  </div>
                </form>
                <div className="col-md-12  hidden-content" style={{display:"none"}}>
                  <h5 className="blue-label">Previous Messages</h5>
                  <div className="prev-messages">
                    <h6>Jul 28, 2021, 11:26 am - Mohit Dua</h6>
                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form.</p>
                    <p>In publishing and graphic design, Lorem ipsum is a form of a document or a typeface without relying on meaningful content.</p>
                  </div>
                  <div className="prev-messages">
                    <h6>Jul 28, 2021, 11:26 am - Mohit Dua</h6>
                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="issue-response-form issue-insight">
              <div className="row">
                <div className="col-md-12">
                  <h5>Issue Insight</h5>
                </div>
                <div className="col-md-12">
                  <label>Lessons learned by {capitalizeFirstLetter(companyID?.company?.company?.short_name)}</label>
                  <textarea maxLength={5000} onChange={(e) => { setLessonlearned(e.target.value) }} className="form-control" placeholder="Lessons learned..." ></textarea>
                </div>
              </div>
            </div>

            <div className="issue-response-form">
                <div className="row">
                  <div className="additional-message-cntnr mt-3">
                    <div className="custom-check-wrpr">
                      <div className="custom-control custom-checkbox text-end">
                        <input onChange={(e)=>{handleCheckboxChange(e)}} type="checkbox" className="custom-control-input" id="closemsg" name="closemsg" />
                        <textarea maxLength={5000} onChange={(e) => { setHowissueclosed(e.target.value) }} className="form-control d-none" placeholder="Enter how the issue is closed"></textarea>
                        <label className="custom-control-label" htmlFor="closemsg">Was the issue closed by the {companyID?.company?.company?.short_name}</label>
                      </div>
                    </div> 
                  </div>
                  <div className="error-msg">
                         {issueclosedErr?"⚠ How the issue is closed message is  required":''} 
                  </div>
            </div>
            </div>
            <div className="issue-response-form">
              <div className="row">
                <div className="col-md-8">
                  <label>&nbsp;</label>
                </div>
                <div className="col-md-4 text-end">
                    {/* <Popconfirm
                      placement="left"
                      title={"Are you sure you want to " + (issueClosed==='YES'? "close this issue?" : "Submit?")}
                      onConfirm={(event) => lessonSubmit(event)}
                      okText="Yes"
                      cancelText="No"
                    > */}
                    
                      <Button 
                      onPress={(e)=>{lessonSubmit(e)}}
                      disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? true: false}
                      className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === schemaData?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}
                      color="primary" 
                      size="sm" 
                      radius="full" >
                      {isLessonLoading ? <Spinner className="testspinner" color="default" size="md" /> : null}
                      Submit
                      </Button>
                    {/* </Popconfirm> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenstate}
        onOk={() => (false)}
        onCancel={() => setIsOpenstate(false)}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={handleSubmitForm2(onSubmitForm2)} >
                <ModalHeader className="flex flex-col gap-1">Select Date And Time</ModalHeader>
                <ModalBody>
                  <DateTimePicker register={registerForm2} showTime onChange={handleDateChange} onOk={handleOk} value={datetime} format="YYYY-MM-DD HH:mm" />
                  <div className="error-msg">
                    {errorsForm2.datetime?.message} {errName}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onClick={() => setIsOpenstate(false)}>
                    Close
                  </Button>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
