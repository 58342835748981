import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import CustomSelect from "../../common/customSelect";

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
}) {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
              {editValue ? "Update Ship Type" : " Add Ship Type"}
              </ModalHeader>
              <ModalBody>

                <div class="mb-3 mt-2">
                   <Input
                    {...register("type")}
                    id="type"
                    type="text"
                    label="Ship Type"
                    labelPlacement="outside"
                    placeholder="Enter Ship Type"
                  />
                  <div className="error-msg">
                    {errors.type?.message} {errName}
                  </div>
                </div>
                <div class="mb-3 mt-2">
                
                <CustomSelect  
                label="Select Status" 
                 name="status" register={register} handleSelectChange ={handleSelectChange} defaultSelectedKeys={[editValue?.status || "ACTIVE"]} options="statusOptions" />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
