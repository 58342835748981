import React, { useEffect, useState } from "react";
import authQueries from "../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../store/userStore';
import {Spinner} from "@nextui-org/react";
import RouteConstants from "../../Constants/RouteConstant";


export default function ReportDash() {
    const [result, setResult] = useState([]);
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState("ACTIVE");
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [isLoading, setIsLoading] = useState(false);
    const companydata = userStore();


    useEffect(() => {
      setIsLoading(true); 
      mutateAsync({ search: "", status: "", page: 0, size: 10 })
      .then(() => {
          setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false); 
      });
      //companyDataCount.mutateAsync({search: "", status: "", page:0, size:1000, schema:companydata?.companyData?.company});
    }, []);


      const { mutateAsync } = authQueries.useCompanyListMutation((response) => {
        console.log('test data',response.data.dataItems)
        setResult(response.data);
      });
    

    const report = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.REPORT)
    }

    const vesselChart = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.VESSELCHART)
    }

    const categoryreport = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.CATEGORYREPORT)
    }

    const vesselcompanyreport = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.VESSELCOMPANYREPORT)
    }
    
    

  return (
    <>
    
    <div className="companies-heading">
        <div className="row">
            <div className="col-md-6 d-flex align-items-center">
                <h4>Companies</h4>
            </div>
        </div>
    </div>
    {isLoading ? 
      (
      <div className="loader" >
            <Spinner size="large" />
      </div>
      ) : (
    <div className="companies-list">
    {result?.dataItems?.map((item, index) => {
       //companyDataCount.mutateAsync({search: "", status: "", page:0, size:1000, schema:item.id});
      return (
        
                    <div className="row list-items">
                        <div className="col-md-1 pe-0 w-5">
                            <div className="company-number">
                                #
                                <span className="number">{(index + 1) + result?.currentPage * 10}</span>
                            </div>
                            
                        </div>
                        <div className="col-md-2 col-md-2 ps-0">
                            <div className="company-name">
                                Company 
                                <span className="c-name">{item?.company?.name}</span>
                            </div>
                        </div>
                        <div className="col-md-5 d-flex pe-0">
                            <div className="list-action-wrpr text-right roundicons-text">

                                <a className="no-count"  onClick={()=>{report(item?.company)}} data-toggle="tooltip" title="" data-original-title="Open Issues">
                                    <i data-count={item?.datacount?.complaintCount} className="reports vessels icon-custom-image"></i>
                                    <span>Vessel Report</span>
                                </a>
                                <a className="no-count"  onClick={()=>{vesselChart(item?.company)}} data-toggle="tooltip" title="" data-original-title="Open Issues">
                                    <i data-count={item?.datacount?.complaintCount} className="reports icon-domain icon-info-outline"></i>
                                    <span>Charts</span>
                                </a>
                                <a className="no-count"  onClick={()=>{categoryreport(item?.company)}} data-toggle="tooltip" title="" data-original-title="Open Issues">
                                    <i data-count={item?.datacount?.complaintCount} className="reports icon-domain icon-info-outline"></i>
                                    <span>Issue from category</span>
                                </a>
                                <a className="no-count"  onClick={()=>{vesselcompanyreport(item?.company)}} data-toggle="tooltip" title="" data-original-title="Open Issues">
                                <i data-count={item?.datacount?.complaintCount} className="reports vessels icon-custom-image"></i>
                                <span>Issue from vessel</span>
                            </a>
                            </div>
                        </div>
                    </div>
                          )
                    })} 
                    <div className="pagination-wrpr">
                    <Pagination  
                      showControls
                      total={result?.totalPages} 
                      onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
                      initialPage={1}  
                    />
                    </div>
                  </div>
    )}

</>
  )
}