import React, { useState } from 'react';
import * as yup from "yup";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import authQueries from "../../queries/authQueries";
import { message as messages } from "antd";
import { useParams, useNavigate } from 'react-router-dom';


const validationSchema = yup.object({
    newpass: yup
      .string("⚠ Enter your New Password")
      .required("⚠ New Password is required")
      .min(4, "⚠ Minimum 4 length"),
    confirmpass: yup
      .string("⚠ Confirm your password")
      .required("⚠ Confirm password is required")
      .min(4, "⚠ Minimum 4 length")
      .oneOf([yup.ref('newpass'), null], '⚠ Passwords must match'),
  });


const SetNewPassword = () => {

    const [errName, setErrName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

  const onSubmit = (data) => {

    const datavalues={
        id:id,
        pass:data.confirmpass
      }
      resetPass.mutateAsync(datavalues).then(() => {
        setIsLoading(false)
        messages.success("Password has been changed successfully");
      });

  };
  const resetPass = authQueries.useResetpass((response) => {
    if (response?.message === "Success") {
        navigate("/login");
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

return (
<div className="login">
<div class="login-wrpr">
<div class="row">
	<div class="col-md-12 logo-wrpr">
		<img class="logo"  src={require("../../assets/images/logo.png")} />
	</div>
	
	<div class="col-md-6">
		
	</div>
	<div class="col-md-6">
		<div class="login-box forgotpass-box">
			<h4 class="text-center">Reset Password</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
			  <div class="mb-3 mt-3">
				<label for="newpass" class="form-label">New Password:</label>
				<input type="password" {...register("newpass")} class="form-control" id="newpass"  name="newpass"/>
                <div className="error-msg">{errors.newpass?.message}</div>
			  </div>
			  <div class="mb-3">
				<label for="confirmnewpass" class="form-label">Confirm New Password:</label>
				<input type="password" {...register("confirmpass")} class="form-control" id="confirmpass"  name="confirmpass" />
                <div className="error-msg">{errors.confirmpass?.message}</div>
			  </div>
			  <div class="signin-btn-wrpr text-center">
			  <button type="submit" class="btn btn-signin btn-resetpass btn-primary">Reset Password <img  src={require("../../assets/images/sign-in-arrow-icon.png")} /></button>
			  </div>
			  <div class="backtohome-btn-wrpr text-center">
					<a onClick={()=>{navigate('/login')}} class="backtohome" href="#">Back to Login</a>
				</div>
			</form>
			<div class="successfull-password-reset">
				<div class="icon-checkmark1"></div>
				<h4>Successful Password Reset</h4>
				<p>You can now use your new password to login to your account</p>
				<div class="signin-btn-wrpr text-center">
				  <button type="submit" class="btn btn-signin btn-primary">Login <img  src={require("../../assets/images/sign-in-arrow-icon.png")} /></button>
				  </div>
			</div>
		</div>
	</div>
</div>
	
</div>
</div>
  );
};

export default SetNewPassword;
