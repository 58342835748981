import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
	Input,
	Textarea,
	Select,
    SelectItem,
  } from "@nextui-org/react";
import '../assets/css/site.css';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import authQueries from "../queries/authQueries";
import { message, message as messages, Popconfirm } from "antd";
import {Spinner} from "@nextui-org/react";
import PhoneInput from 'react-phone-input-2'


const contactUsValidationSchema = yup.object({
	fname: yup
	  .string("⚠ Enter Your First Name")
	  .required("⚠ Your First Name is required")
	  .min(3, "⚠ Your First Name must be Min 3 characters "),
	lname: yup
	  .string("⚠ Enter Your Last Name")
	  .required("⚠ Your Last Name is required")
	  .min(3, "⚠ Your Last Name must be Min 3 characters "),
	email: yup
	  .string("⚠ Enter Email")
	  .required("⚠ Email is required")
	  .email("⚠ Enter a valid email address"),
	message: yup
	  .string("⚠ Enter Your Message")
	  .required("⚠ Message is required"),
  });


const Contact = ({ isModalopen, setisModalopen}) => {


    const [isLoading, setIsLoading] = useState(false);
    const [errName, setErrName] = useState("");
    const [code, setCode] = useState('in');

    const {
        control,
		register: registerUser,
		reset: resetUser,
		handleSubmit: handleSubmitUser,
		formState: { errors: errorsUser },
	  } = useForm({
		resolver: yupResolver(contactUsValidationSchema),
	  });


      const onContactSubmit = async (data) => {
		setIsLoading(true);
		const datavalues = {
		    fname: data.fname,
			lname: data.lname,
			email:data.email,
			telephone: data.telephone,
			mobile:data.mobile,
			message: data.message
		  };

		  ContactAPI.mutateAsync(datavalues).then(() => {
			setIsLoading(false);
			let datas = {
				fname: "",
				lname: "",
				email:"",
				telephone:"",
				phone:"",
				message: "",
			};
			resetUser({ ...datas });
		  });

	}

    const ContactAPI = authQueries.useContactus((response) => {
		if (response?.message === "Success") {
		messages.success("Your Query has been Submitted Successfully");
		  setisModalopen(false);
		} else if (response?.error[0].name) {
		  setErrName("⚠ " + response?.error[0].name);
		}
	  });


    return ( 

        <Modal 
        backdrop="blur" 
        isOpen={isModalopen} 
        onOpenChange={(newState)=>setisModalopen(newState)}
        size="2xl"

      >
		 <form onSubmit={handleSubmitUser(onContactSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
				Contact Us
				<p className="demonote">To get more information about SHIPCONREP or any queries related to SHIPCONREP</p>
			  </ModalHeader>
			  <ModalBody>
                <div className="row">
                <div className="col-md-6">
                    <div className="mb-3 mt-2">
                      <Input
                        {...registerUser("fname")}
                        id="code"
                        type="text"
                        label={<div> Firstname <span className="required"></span></div>}
                        labelPlacement="outside"
                        placeholder="Enter Firstname"
                        maxLength={200}
                      />
                      <div className="error-msg">
                        {errorsUser.fname?.message} {errName}
                      </div>
                    </div>
				</div>
				<div className="col-md-6">
					<div className="mb-3 mt-2">
						<Input
							{...registerUser("lname")}
							id="code"
							type="text"
							label={<div> Lastname <span className="required"></span></div>}
							labelPlacement="outside"
							placeholder="Enter Lastname"
              maxLength={200}
						/>
						<div className="error-msg">
							{errorsUser.lname?.message} {errName}
						</div>
						</div>
				</div>

            
				<div className="col-md-12">
					
					<div className="mb-3 mt-2">
						<Input
							{...registerUser("email")}
							id="code"
							type="text"
							label={<div> Work Email <span className="required"></span></div>}
							labelPlacement="outside"
							placeholder="Enter Email"
              maxLength={200}
						/>
						<div className="error-msg">
							{errorsUser.email?.message} {errName}
						</div>
					</div>

				</div>

                  <div className="col-md-6">

                    <div className="mb-3 mt-2">
                    <label style={{fontSize:'14px'}}>Telephone Number</label>
                    <Controller
                      name='telephone'
                      control={control}
                      className="form-control h-[38px]"
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            enableSearch={true}
                            country={code}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                    </div>
					</div>
					<div className="col-md-6">


                    
					<div className="mb-3 mt-2">
                    <label style={{fontSize:'14px'}}>Mobile Number</label>
                    <Controller
                      name='mobile'
                      control={control}
                      className="form-control h-[38px]"
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            enableSearch={true}
                            country={code}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                    </div>
					</div>
				  <div className="mb-3 mt-2">
                      <Textarea
                        {...registerUser("message")}
                        variant="faded"
                        label={<div> Message <span className="required"></span></div>}
                        labelPlacement="outside"
                        placeholder="Enter Message"
                        name="message"
                        maxLength={200}
                      />
					<div className="error-msg">
                     {errorsUser.message?.message} {errName}
                    </div>
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                 SUBMIT
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
		</form>
      </Modal>

    )


}

export default Contact