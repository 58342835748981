import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import LoaderScreen from "../../common/LoaderScreen";
import RouteConstants from "../../../Constants/RouteConstant";
const validationSchema = yup.object({
  country: yup
    .string("⚠ Enter Country")
    .required("⚠ Country is required")
    .min(2, "⚠ Country must be more than 2 characters ")
});

const CountryList = () => {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    mutateAsync({ search: "", status: "", page: 0, size: 10 });
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useCountryListMutation((response) => {
    setResult(response.data);
    setLoading(false)
  });

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const setInitialValue = (data) => {
    setEditValue(data);
    let datas = {
      id: data.id,
      country: data.country,
      status: data.status,
    };
    setSelectedValue(data.status)
    reset({ ...datas });
    onOpen(true);
  };

  const AddData = authQueries.useCountryAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.COUNTRY);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useCountryUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: "", status: "", page: 0, size: 10 });
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useCountryMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 });
    messages.success("successfully deleted");
  });

  const onSubmit = (data) => {
    const datavalues = {
      country: data.country,
      status: data.status,
    };
    if (!editValue) {
      AddData.mutateAsync(datavalues).then(() => {
        onOpenChange(false);
        mutateAsync({ search: "", status: "", page: 0, size: 10 });
        let datas = {
          country: "",
          status: "ACTIVE",
        };
        setSelectedValue("ACTIVE")
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        country: data.country,
        status: selectedValue,
      };
      updateData.mutateAsync(datavalues).then(() => {
        let datas = {
          country: "",
          status: "ACTIVE",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    event.preventDefault();
    deleteData.mutateAsync(id);
  };
  const addButton = ()=>{
    setEditValue(null);
    onOpen(true);
    let datas = {
      country: "",
      status: "ACTIVE",
    };
    reset({ ...datas });
  }

  return (
    <div className="container">
      <div className="row">
        {
          loading ?
          <LoaderScreen />:
          <div className="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
            <div className="text-end p-3">
              <Button
                color="primary"
                className="btn btn-add"
                onPress={addButton}
              >
                <i className="icon-add"></i> Add
              </Button>
            </div>
            <div className="table-wrpr">
              <div className="table-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th width="20">#</th>
                      <th width="100">Country</th>
                      <th width="250">Status</th>
                      <th width="50">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.items?.map((item, index) => (
                      <tr key={index}>
                        <td>{(index + 1) + result?.currentPage * 10}</td>
                        <td>{item.country}</td>
                        <td>
                          <span className="status open">{item.status}</span>
                        </td>
                        <td className="action-links">
                          <a
                            href="#"
                            onClick={() => setInitialValue(item)}
                            className="btn btn-view"
                          >
                            Edit
                          </a>

                          <Popconfirm
                            placement="left"
                            title="Are you sure to delete this ?"
                            onConfirm={(event) => {
                              deleteEvent(event, item.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="btn btn-view">Delete</button>
                          </Popconfirm>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination-wrpr">
              <Pagination  
              showControls
              total={result?.totalPages} 
              onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
              initialPage={1}  
              />
            </div>
          </div>
        }
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
      />
    </div>
  );
};
export default CountryList;
