import { Spinner } from '@nextui-org/react'
import React from 'react'

const LoaderScreen = () => {
  return (
    <div>
        <div className="flex justify-center loader-screen">
          <Spinner />
        </div>
    </div>
  )
}

export default LoaderScreen