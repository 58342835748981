import React,{useState, useEffect} from "react";
import { Chart } from "react-google-charts";
import authQueries from "../../../queries/authQueries";
import { userStore } from '../../../store/userStore';
import {
  Spinner,
} from "@nextui-org/react";
export default function CategoryPiechart() {

      const [result, setResult] = useState([]);
      const [vessel, setVessel] = useState([]);
      const [platform, setPlatform] = useState([]);
      const [errName, setErrName] = useState("");
      const companyID = userStore();

      const initCall =  async()=>{
        await mutateAsync({ schema:companyID?.companyData?.company });
        await vesselChart.mutateAsync({ schema:companyID?.companyData?.company});
        await platformChart.mutateAsync({ schema:companyID?.companyData?.company});
      }

      useEffect(() => {
        initCall();
      }, []);

      const { mutateAsync } = authQueries.useCategoryChart((response) => {
        setResult(response.data);
      });


    const vesselChart = authQueries.useVesselChart(
      (response) => {
        setVessel(response.data);
      }
    );

    const platformChart = authQueries.usePlatformChart(
      (response) => {
        setPlatform(response.data);
      }
    );

  
      // const ctegoryOptions = {
      //   title: "Category",
      //   is3D: true,
      // };

      const ctegoryOptions = {
       // title: 'Category',
        is3D: true,
        width: '100%',
        height: 500,
        'chartArea': {'width': '80%', 'height': '80%'},
        legend: { position: "bottom" },
      };

      

      // const vesselOptions = {
      //   chart: {
      //     title: "Vessel Submission",
      //     subtitle: "vessels, issue submision count",
      //   },
      // };

      const vesselOptions = {
        title: "Vessel Submission",
        width: '100%',
        height: 500,
        bar: {groupWidth: "40%"},
        'chartArea': {'width': '50%', 'height': '90%'},
        //chartArea:{left:10,top:20,width:"100%",height:"100%"},
        legend: { position: "top" },
        };

      var platformOptions = {
        //title: 'Platform',
        is3D: true,
        width: '100%',
        height: 500,
        'chartArea': {'width': '80%', 'height': '80%'},
        legend: { position: "bottom" },
      };

      // const platformOptions = {
      //   title: "Platform",
      //   is3D: true,
      // };

      return (

            
                    <div class="container-fluid">
                        <div class="row">              
                          <div class="col-md-6">
                              <div class="chart-wrpr3">

                                <div class="chart-header">
                                    <h5>Category</h5>
                            
                                </div>

                                {result.length>0 ? (
                                  <Chart
                                    chartType="PieChart"
                                    data={result}
                                    options={ctegoryOptions}
                                    width={"100%"}
                                    height={"400px"}
                                  />
                                  ):<Spinner className="testspinner" color="Primary" size="lg" />}

                               
                                

                        </div>
                    </div>

                    <div class="col-md-6">
                      <div class="chart-wrpr4">
                            <div class="chart-header">
                              <h5>Platform</h5>
                            </div>
                            {platform.length>0 ? (
                                    <Chart
                                      chartType="PieChart"
                                      data={platform}
                                      options={platformOptions}
                                      width={"100%"}
                                      height={"400px"}
                                    />
                                  ):<Spinner className="testspinner" color="Primary" size="lg" />}
                        </div>
                     </div>
                     <div class="col-md-12">
					
                          <div class="chart-wrpr">
                              <div class="chart-header">
                                <h5>Issue submitted from vessel</h5>
                              </div>
                              {vessel.length>0 ? (
                                    <Chart
                                      chartType="Bar"
                                      width="100%"
                                      height="400px"
                                      data={vessel}
                                      options={vesselOptions}
                                    />
                                    ):<Spinner className="testspinner" color="Primary" size="lg" />}

                            </div>
                       </div>


                    </div>
                      
                    </div>

      );
      

}