import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useLocation, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore'
import RouteConstants from '../../../Constants/RouteConstant';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
	Input,
	Textarea,
	Select,
    SelectItem,
  } from "@nextui-org/react";
  import {Spinner} from "@nextui-org/react";
  import { useForm } from "react-hook-form";
  import * as yup from "yup";
  import { yupResolver } from "@hookform/resolvers/yup";
  import FilterSelect from "../../../components/common/Layout/filterSelect";
  import authQueries from "../../../queries/authQueries";
  import { message, message as messages, Popconfirm } from "antd";

  
const validationSchema = yup.object({
	email: yup
	  .string("⚠ Enter Email")
	  .required("⚠ Email is required")
	  .email("⚠ Enter a valid email address"),
	subject: yup
	  .string("⚠ Enter Your Subject")
	  .required("⚠ Subject is required")
	  .min(5, "⚠ Subject must be Min  5 characters "),
  
  });


const Sidebar = () => {

    const user = userStore();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [errName, setErrName] = useState("");
    const location = useLocation()

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(validationSchema),
      });


          
  useEffect(() => {
    setValue("email", user?.user?.email)
  }, []);

      const onSubmit = async (data) => {
		setIsLoading(true);
		const datavalues = {
			email:data.email,
		    subject: data.subject,
			message: data.message,
            id:user.user.id
		  };

          HelpAPI.mutateAsync(datavalues).then(() => {
			setIsLoading(false);
			let datas = {
				subject:"",
				email:"",
                message:""

			};
			reset({ ...datas });
		  });

	}

    const HelpAPI = authQueries.useHelp((response) => {
		if (response?.message === "Success") {
		messages.success("Thankyou for contacting us");
		  onOpenChange(false);
		} else if (response?.error[0].name) {
		  setErrName("⚠ " + response?.error[0].name);
		}
	  });

    const shipConRepAdminItems = [
        {
            key:1,
            active:"DASHBOARD",
            label: "Dashboard - Workplace Code Issues",
            icon: "icon-dashboard",
            navigate: RouteConstants.DASHBOARD,
            className: "dashboard"
        },
        {
            key:7,
            active:"ISSUES",
            label: "No Workplace Code Issues",
            icon: "icon-people_outline",
            navigate: RouteConstants.PUBLICISSUES,
            className: "master"
        },
        {
            key:2,
            active:"COMPANY",
            label: "Company",
            icon: "icon-domain",
            navigate: RouteConstants.COMPANY,
            className: "company"
        },
        {
            key:3,
            active:"SHIP",
            label: "Ship Type",
            icon: "icon-people_outline",
            navigate: RouteConstants.SHIPTYPELIST,
            className: "master"
        },
        {
            key:4,
            active:"RANK",
            label: "Rank On Board",
            icon: "icon-people_outline",
            navigate: RouteConstants.RANKLIST,
            className: "master"
        },
        {
            key:5,
            active:"CATEGORY",
            label: "Category",
            icon: "icon-people_outline",
            navigate: RouteConstants.CATEGORY,
            className: "master"
        },
        {
            key:6,
            active:"COUNTRY",
            label: "Country",
            icon: "icon-people_outline",
            navigate: RouteConstants.COUNTRY,
            className: "master"
        },
        
        {
            key:8,
            active:"REPORTS",
            label: "Reports Work Place Code",
            icon: "icon-people_outline",
            navigate: RouteConstants.REPORTDASH,
            className: "master"
        },
        {
            key:9,
            active:"NOCODEREPORTS",
            label: "Reports No Workplace Code",
            icon: "icon-people_outline",
            navigate: RouteConstants.NOCODEREPORT,
            className: "master"
        },
        {
            key:14,
            active:"TOTALREPORT",
            label: "Reports Total By Category - No workplace code and workplace code",
            icon: "icon-people_outline",
            navigate: RouteConstants.TOTALREPORT,
            className: "master"
        },

        {
            key:12,
            active:"CATEGORYREPORT",
            label: "Reports No Workplace Code - by Category",
            icon: "icon-people_outline",
            navigate: RouteConstants.CATEGORYNOCODE,
            className: "master"
        },
        {
            key:13,
            active:"RANKONBOARDREPORT",
            label: "Reports No Workplace Code - by Rank on Board",
            icon: "icon-people_outline",
            navigate: RouteConstants.RANKONBOARDNOCODE,
            className: "master"
        },
        
        {
            key:11,
            active:"SURVEYREPORT",
            label: "Reports - Survey",
            icon: "icon-people_outline",
            navigate: RouteConstants.SURVEYREPORT,
            className: "master"
        },
        {
            key:10,
            active:"DEMO",
            label: "Request for Demo",
            icon: "icon-people_outline",
            navigate: RouteConstants.DEMO,
            className: "master"
        },
        {
            key:15,
            active:"PROFILE",
            label: "App Profiles",
            icon: "icon-people_outline",
            navigate: RouteConstants.PROFILE,
            className: "master"
        },
       
        
    ]

    const superUserItems = [
        {
            key:1,
            active:"DASHBOARD",
            label: "Dashboard",
            icon: "icon-dashboard",
            navigate: RouteConstants.DASHBOARD,
            className: "dashboard"
        },
        {
            key:2,
            active:"FAQ",
            label: "FAQ",
            icon: "icon-people_outline",
            navigate: RouteConstants.EMPLOYERFAQ,
            className: "master"
        },
    ]

    const superAdminItems = [
        {
            key:1,
            active:"DASHBOARD",
            label: "Dashboard",
            icon: "icon-dashboard",
            navigate: RouteConstants.DASHBOARD,
            className: "dashboard"
        },
        {
            key:2,
            active:"DOC",
            label: "Doc Holder",
            icon: "icon-directions_boat",
            navigate: RouteConstants.DOC,
            className: "master"
        },
        {
            key:3,
            active:"FLEET",
            label: "Fleet",
            icon: "icon-people_outline",
            navigate: RouteConstants.FLEET,
            className: "master"
        },
        {
            key:4,
            active:"VESSEL",
            label: "Vessel",
            icon: "icon-people_outline",
            navigate: RouteConstants.VESSEL,
            className: "master"
        },        
        {
            key:5,
            active:"USER",
            label: "User",
            icon: "icon-people_outline",
            navigate: RouteConstants.USER,
            className: "master"
        },
        {
            key:6,
            active:"FAQ",
            label: "FAQ",
            icon: "icon-people_outline",
            navigate: RouteConstants.EMPLOYERFAQ,
            className: "master"
        },
    ]

    const accountUserItems = [
        {
            key:1,
            active:"DASHBOARD",
            label: "Dashboard",
            icon: "icon-dashboard",
            navigate: RouteConstants.DASHBOARD,
            className: "dashboard"
        },
        {
            key:2,
            active:"FAQ",
            label: "FAQ",
            icon: "icon-people_outline",
            navigate: RouteConstants.EMPLOYERFAQ,
            className: "master"
        },
    ]

    useEffect(()=> {
        if(user?.user?.role === "SHIPCONREPADMIN"){
            shipConRepAdminItems.map(item => {
                if(item.navigate === location.pathname)
                user.setSideBarActiveTab(item.active)
            })
        }else if(user.user.role === 'SUPERADMIN'){
            superAdminItems.map(item => {
                if(item.navigate === location.pathname)
                user.setSideBarActiveTab(item.active)
            })
        }else if(user.user.role === 'SUPERUSER'){
            superUserItems.map(item => {
                if(item.navigate === location.pathname)
                user.setSideBarActiveTab(item.active)
            })
        }else{
            accountUserItems.map(item => {
                if(item.navigate === location.pathname)
                user.setSideBarActiveTab(item.active)
            })
        }
    },[location.pathname])

  return (
    <>
    <aside className="left-side side-off-canvas ">
    <div className="logo-cntnr" onClick={()=>{ navigate(RouteConstants.DASHBOARD);}} >
        <img  src={require("../../../assets/images/logo-white.png")} width="" height="" alt=""/>
    </div>
    
    {
     user.user.role === 'SHIPCONREPADMIN' ?
        <ul className="sidebar-menu nav">
            {
                shipConRepAdminItems?.map((item, index)=> (
                    <li key={index} className={item?.active === user?.activeTab ? `${item?.className} active` : item?.className}>
                        <a 
                            className='text-white cursor-pointer'
                            onClick={()=>{
                            user?.setSideBarActiveTab(item?.active)
                            navigate(item?.navigate)
                            }}
                        >
                            {item?.label}<span className={item?.icon}></span>
                        </a>
                    </li>
                ))
            }
        <li onClick={onOpen} class="master"><a class="text-white cursor-pointer">Help<span class="icon-people_outline"></span></a></li>
        </ul>
    :
    user.user.role === 'SUPERADMIN' ?
    <ul className="sidebar-menu nav">
        {
            superAdminItems?.map((item, index)=> (
                <li key={index} className={item?.active === user?.activeTab ? `${item?.className} active` : item?.className}>
                    <a 
                        className='text-white cursor-pointer'
                        onClick={()=>{
                        user?.setSideBarActiveTab(item?.active)
                        navigate(item?.navigate)
                        }}
                    >
                        {item?.label}<span className={item?.icon}></span>
                        
                    </a>
                </li>
            ))
        }
        <li onClick={onOpen} class="master"><a class="text-white cursor-pointer">Help<span class="icon-people_outline"></span></a></li>
    </ul>:
    user.user.role === 'ACCOUNTUSER' ?
    <ul className="sidebar-menu nav">
        {
            accountUserItems?.map((item, index)=> (
                <li key={index} className={item?.active === user?.activeTab ? `${item?.className} active` : item?.className}>
                    <a 
                        className='text-white cursor-pointer'
                        onClick={()=>{
                        user?.setSideBarActiveTab(item?.active)
                        navigate(item?.navigate)
                        }}
                    >
                        {item?.label}<span className={item?.icon}></span>
                        
                    </a>
                </li>
            ))
        }
        <li onClick={onOpen} class="master"><a class="text-white cursor-pointer">Help<span class="icon-people_outline"></span></a></li>
    </ul>:
    user.user.role === 'SUPERUSER' &&
        <ul className="sidebar-menu nav">
        {
            superUserItems?.map((item, index)=> (
                <li key={index} className={item?.active === user?.activeTab ? `${item?.className} active` : item?.className}>
                    <a 
                        className='text-white cursor-pointer'
                        onClick={()=>{
                        user?.setSideBarActiveTab(item?.active)
                        navigate(item?.navigate)
                        }}
                    >
                        {item?.label}<span className={item?.icon}></span>
                        
                    </a>
                </li>
            ))
        }
         <li onClick={onOpen} class="master"><a class="text-white cursor-pointer">Help<span class="icon-people_outline"></span></a></li>
    </ul>
    }



</aside>
<Header/ >

<Modal 
        backdrop="blur" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        size="2xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
             <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader className="flex flex-col gap-1">
				Contact  Us
				<p className="demonote">In case of any difficulties in using portal please send your queries to us</p>
			  </ModalHeader>
			  <ModalBody>
               
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 mt-2">
                      <Input
                      {...register("email")}
                        id="email"
                        type="text"
                        label="Your Email Address"
                        labelPlacement="outside"
                        placeholder="Enter your Email Address"
                        required
                        maxLength={200}
                      />
                      <div className="error-msg">
                        {errors.email?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("subject")}
                        id="subject"
                        type="text"
						label="Subject"
                        labelPlacement="outside"
                        placeholder="Enter your Subject"
                        maxLength={200}
                      />
                       <div className="error-msg">
                        {errors.subject?.message} {errName}
                      </div>
                    </div>
                    <div className="mb-3 mt-2">
                      <Textarea
                       {...register("message")}
                        variant="faded"
                        label="How can we help you?"
                        labelPlacement="outside"
                        placeholder="Enter Your Message"
                        name="message"
                        maxRows="8"
                        maxLength={200}
                      />
                    </div>
                  </div>
                </div>
                
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                 SUBMIT
                </Button>
              </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
		
      </Modal>


</>
  )
}

export default Sidebar