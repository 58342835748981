import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { userStore } from '../../../store/userStore';
import { useNavigate } from "react-router-dom";
import { Pagination } from '@nextui-org/react';
import RouteConstants from "../../../Constants/RouteConstant";
import { formatTimestamp, calculateDaysTillToday, extractDateFromTimestamp } from '../../../helpers/dateFormatter';
import { Modal, Button, Form } from 'react-bootstrap'; 
import warningSvg from './awarning.svg';
import warningfillSvg from './awarningfill.svg';
import notificationSvg from './anotification.svg';

const AccountUserDashboard = () => {
    const [result, setResult] = useState([]);
    const [allIssues, setAllIssues] = useState([]); // State to store all issues
    const companyID = userStore();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [docholder, setHocholder] = useState([]);
    const [doc, setDoc] = useState('');
    const store = userStore();

    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [openIssuesCount, setOpenIssuesCount] = useState(0); // State to hold the count of open issues
    const [selectedFilter, setSelectedFilter] = useState(''); // State to hold the selected filter
    const [initialLogin, setInitialLogin] = useState(true); // State to check if it is initial login

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const fetchAllIssues = async () => {
        let allFetchedIssues = [];
        let page = 0;
        let totalPages = 1;

        while (page < totalPages) {
            const issuesResponse = await issueList.mutateAsync({
                search: "",
                status: "",
                page: page,
                size: 100, // Fetch a large number of issues per request to reduce the number of requests
                schema: companyID?.companyData?.company,
                doc: store?.companyFilter?.selectedDoc,
                vessel: store?.companyFilter?.selectedVessel,
                accountStatus: store?.companyFilter?.selectedAccntStatus,
                category: store?.companyFilter?.selectedCategory,
                platform: store?.companyFilter?.selectedPlatform,
                reference: store?.companyFilter?.selectedReference
            });

            allFetchedIssues = [...allFetchedIssues, ...issuesResponse?.data?.items];
            totalPages = issuesResponse?.data?.totalPages;
            page += 1;
        }

        setAllIssues(allFetchedIssues);
        applyFilter(allFetchedIssues, selectedFilter); // Apply filter when fetching issues
    };

    const InitCall = async () => {
        fetchAllIssues();

        const issuesResponse = await issueList.mutateAsync({
            search: "",
            status: "",
            page: 0,
            size: pageSize,
            schema: companyID?.companyData?.company,
            doc: store?.companyFilter?.selectedDoc,
            vessel: store?.companyFilter?.selectedVessel,
            accountStatus: store?.companyFilter?.selectedAccntStatus,
            category: store?.companyFilter?.selectedCategory,
            platform: store?.companyFilter?.selectedPlatform,
            reference: store?.companyFilter?.selectedReference
        });

        setResult(issuesResponse?.data);
    }

    const docListing = authQueries.useDocListMutation(
        (response) => {
            setHocholder(response?.data?.items);
        }
    );

    const issueList = authQueries.useComplaintIssuesMutation(
        (response) => {
            setResult(response?.data);
        }
    );

    const applyFilter = (issues, filter) => {
        let filteredIssues = issues;

        if (filter === '30') {
            filteredIssues = issues?.filter(item => {
                const issueCreated_at = extractDateFromTimestamp(item?.created_at);
                return item?.complaint_status?.status === 'OPEN' && calculateDaysTillToday(issueCreated_at) >= 30;
            });
        } else if (filter === '60') {
            filteredIssues = issues?.filter(item => {
                const issueCreated_at = extractDateFromTimestamp(item?.created_at);
                return item?.complaint_status?.status === 'OPEN' && calculateDaysTillToday(issueCreated_at) >= 60;
            });
        } else if (filter === '90') {
            filteredIssues = issues?.filter(item => {
                const issueCreated_at = extractDateFromTimestamp(item?.created_at);
                return item?.complaint_status?.status === 'OPEN' && calculateDaysTillToday(issueCreated_at) >= 90;
            });
        }

        setResult({ ...result, items: filteredIssues });
        if (initialLogin) {
            checkForOpenIssues(filteredIssues);
        }
    };

    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
        applyFilter(allIssues, e.target.value);
    };

    const checkForOpenIssues = (issues) => {
        const openIssues = issues?.filter(item => {
            const issueCreated_at = extractDateFromTimestamp(item?.created_at);
            return item?.complaint_status?.status === 'OPEN' && calculateDaysTillToday(issueCreated_at) >= 30;
        });
        const hasOpenIssues = openIssues.length > 0;
        setOpenIssuesCount(openIssues.length);
        if (hasOpenIssues) {
            setShowModal(true); // Show modal if there are open issues for more than 30 days
        }
        setInitialLogin(false); // Set initialLogin to false after the first check
    };

    useEffect(() => {
        let FilterValues = {
            selectedDoc: '',
            selectedVessel: '',
            selectedAccntStatus: '',
            selectedCategory: '',
            selectedPlatform: '',
            selectedReference: ''
        };
        store.setSelectedFilter(FilterValues);
        issueList.mutateAsync({
            search: "",
            status: "",
            page: 0,
            size: 10,
            schema: companyID?.companyData?.company,
            doc: '',
            vessel: '',
            accountStatus: '',
            category: '',
            platform: '',
            reference: ''
        });
    }, []);

    useEffect(() => {
        InitCall();
        setDoc(store?.companyFilter?.selectedDoc);
    }, [store]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="dashboard-section-title">Issues <span className="data-count">{result?.totalItems}</span></h3>
                            </div>
                            <div className="col-md-6">
                                <div className="text-end p-3 pt-0">
                                    <Form.Select value={selectedFilter} onChange={handleFilterChange}>
                                        <option value="">All Issues</option>
                                        <option value="30">Open for more than 30 days</option>
                                        <option value="60">Open for more than 60 days</option>
                                        <option value="90">Open for more than 90 days</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>

                        <div className="table-wrpr">
                            <div className="table-section">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th width="45"></th>
                                            <th width="20">#</th>
                                            <th width="100">Issue Name</th>
                                            <th width="100">Reference Number</th>
                                            <th width="120">Reported Date</th>
                                            <th width="100">Doc Holder</th>
                                            <th width="">Issue Type</th>
                                            <th width="200">What Happened</th>
                                            <th width="100">Status</th>
                                            <th width="50"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {result?.items?.map((item, index) => {
                                            const categories = item?.categories?.filter(item => item.complaint_category && item.complaint_category.status === 'ACTIVE')
                                                .map(item => item.category);
                                            const categoryString = categories?.join(', ');
                                            const issueName = item?.complaint_assigns?.filter(assign => assign.isaccept === 'YES');
                                            const issueCreated_at = extractDateFromTimestamp(item?.created_at);
                                            const isOpenForMoreThan30Days = item?.complaint_status?.status === 'OPEN' && calculateDaysTillToday(issueCreated_at) >= 30;
                                            const isAcceptForMoreThan30Days = item?.complaint_status?.status === 'ACCEPTED' && calculateDaysTillToday(issueCreated_at) >= 30;
                                            const rowClassName = isOpenForMoreThan30Days ? 'highlighted-row' : ''; // Conditionally apply the CSS class

                                            return (
                                                <tr key={item.id} className={rowClassName}>
                                                    <td>{isOpenForMoreThan30Days && (<img data-bs-toggle="tooltip" title='Issue is in open state for more than 30 days' src={warningSvg} alt="Warning Icon" />)}{isAcceptForMoreThan30Days && (<img data-bs-toggle="tooltip" title='Issue is Accepted and not closed for more than 30 days' src={warningfillSvg} alt="WarningFill Icon" />)}</td>
                                                    <td>{(result?.totalItems - index) - result?.currentPage * 10}</td>
                                                    <td>{issueName?.[0]?.nameofissue}</td>
                                                    <td>{item?.reference_num}</td>
                                                    <td>{formatTimestamp(item.created_at)}</td>
                                                    <td>{item.doc_holder?.name}</td>
                                                    <td>{categoryString}</td>
                                                    <td data-bs-toggle="tooltip" title={item.what_happened}>{item?.what_happened?.slice(0, 100)}<i className="icon-popout"></i></td>
                                                    <td>
                                                        <span className={
                                                            `status ${item?.complaint_status?.status === "ACCEPTED" ? "accept" :
                                                                item?.complaint_status?.status === "CLOSED" ? "closed" : 'open'}`
                                                        }>{item?.complaint_status?.status}</span>
                                                    </td>
                                                    <td className="action-links">
                                                        <a href="#" onClick={() => { navigate(RouteConstants.COMPLIANTVIEWURL + item.id); }} className="btn btn-view btn-round icon-eye"></a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="pagination-wrpr">
                            <Pagination
                                showControls
                                total={result?.totalPages}
                                onChange={(e) => {
                                    issueList.mutateAsync({
                                        search: '',
                                        page: e - 1,
                                        size: pageSize,
                                        schema: companyID?.companyData?.company,
                                        doc: store?.companyFilter?.selectedDoc,
                                        vessel: store?.companyFilter?.selectedVessel,
                                        accountStatus: store?.companyFilter?.selectedAccntStatus,
                                        category: store?.companyFilter?.selectedCategory,
                                        platform: store?.companyFilter?.selectedPlatform,
                                        reference: store?.companyFilter?.selectedReference
                                    });
                                    setCurrentPage(e - 1);
                                }}
                                initialPage={1}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Notification</Modal.Title>
                        <img src={notificationSvg} alt="Notification Icon" />
                    </Modal.Header>
                    <Modal.Body>
                        <p>There are {openIssuesCount} concerns highlighted that have not been acknowledged for more than 30 days.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <p className="text-dark">Close</p>
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default AccountUserDashboard;
