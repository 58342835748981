import React from 'react'
import { userStore } from '../../store/userStore'
import ShipAdmin from './shipAdmin/ship-admin-Dashboard';
import CompanyDash from './companyAdmin/companyDash';
import AccountUserDashboard from './accountUser/AccountUserDashboard';
import SuperUserDashboard from './superUser/SuperUserDashboard';


const Dashboard= ()=>{
    const user = userStore();
    return (
        <>
            {
              user.user.role === 'SHIPCONREPADMIN' ? <ShipAdmin /> : 
              user.user.role === 'SUPERADMIN' ? < CompanyDash /> : 
              user.user.role === 'ACCOUNTUSER' ? <AccountUserDashboard /> :
              user.user.role === 'SUPERUSER' && <SuperUserDashboard />
            }
    
        </>
      )
}

export default Dashboard