// StoreContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { userStore } from '../store/userStore'; // Import your Zustand store

const StoreContext = createContext();

export function StoreProvider({ children }) {
  const store = userStore(); // Replace with your Zustand store
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [storeChanged, setStoreChanged] = useState(0);

  // Subscribe to changes in the Zustand store
//   useEffect(() => {
//     // const unsubscribe = store.subscribe(() => {
//     //   // Increment the state to trigger a re-render
//        setStoreChanged((prev) => prev + 1);
//     // });
//     // return () => {
//     //   unsubscribe();
//     // };
//   }, [store]);

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
}

export function useCustomStore() {
  return useContext(StoreContext);
}
