import React from "react";



const EFaq =  () =>{


return(

    <div style={{maxWidth:'1320px'}} class="container faq-wrpr">
		
		<div class="row">
			<div class="col-md-12">
				
				<div id="efaq_accordion">

				  <div class="card">
					<div class="card-header">
					  <a class="btn" data-bs-toggle="collapse" aria-expanded="true" href="#faqCollapseOne">
                      What if I forget my password for login in?
					  </a>
					</div>
					<div id="faqCollapseOne" class="collapse show" data-bs-parent="#efaq_accordion">
					  <div class="card-body">
						<p>Click on “forgot password” on Login page and follow the steps to create a new password.</p>
					  </div>
					</div>
				  </div>

				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseTwo">
                      I am given a first time login password. Can I continue to use that for future login in instead of  
                      changing my password ?

					  </a>
					</div>
					<div id="faqCollapseTwo" class="collapse" data-bs-parent="#efaq_accordion">
					  <div class="card-body">
                      For tracking and accountability purposes, we strongly recommend you to change your password       
                       after first time login. We would advise you to also not share your password with anyone

					  </div>
					</div>
				  </div>

				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseThree">
                      What if an Employee with Super User role ceases to be employed (etc resigns/transferred to 
                     another dept) and another Employee is to take over his functions ?

					  </a>
					</div>
					<div id="faqCollapseThree" class="collapse" data-bs-parent="#efaq_accordion">
					  <div class="card-body">
                      Super Admin is to 
                        -	deactivate status of previous Super User 
                        -	and to create a new Super User role with a new first time login password for the new employee

					  </div>
					</div>
				  </div>
				</div>
			</div>
		</div>
	
</div>


    )
}
export default EFaq;