import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import 'aos/dist/aos.css'; 
import { useNavigate } from "react-router-dom";
import RouteConstants from "../Constants/RouteConstant";
import Contact from "./contactForm";


const Faq =  () =>{
    const [previousScroll, setPreviousScroll] = useState(0);
    const navigate = useNavigate();
    const [isModalopen, setisModalopen]=useState(false);

    const handleScroll = () => {

        const currentScroll = window.scrollY;

        if (currentScroll > 480) {
            NavTrans();
        } else {
            removeNavTrans();
        }

        if (currentScroll < 80) {
            showTopNav();
        } else if (currentScroll > 0 && currentScroll < document.body.scrollHeight - window.innerHeight) {
            if (currentScroll > previousScroll) {
                hideNav();
            } else {
                showNav();
            }
            setPreviousScroll(currentScroll);
        }
    };


	const hideNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-visible');
            navbar.classList.add('is-hidden');
        }
    };

    const showNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden');
            navbar.classList.add('is-visible', 'scrolling');
        }
    };

    const showTopNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden', 'scrolling');
            navbar.classList.add('is-visible');
        }
    };

    const NavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.add('whiteNav');
        }
    };

    const removeNavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('whiteNav');
        }
    };


	useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScroll]);


    useEffect(() => {
        AOS.init();
      }, []);

    return(
        <>
        <nav id="navbar" class="navbar navbar-expand-md ml-3 mr-3  navbar-light  is-visible  fixed-top ">
            <div class="navbar-brand   order-1 order-md-0 dual-collapse2">
                <a onClick={()=>{navigate(RouteConstants.ROOT)}} class="navbar-brand mr-auto">
                    
                   <img class="logo"  src={require("../assets/images/site/logo.png")} />
                </a>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">
                    <span class="navbar-toggler-icon">
                    <span></span>
                    </span>
                </button>
            
            </div>
            <div class="navbar-collapse  mx-auto order-0 header-center-nav" id="collapsingNavbar">
                        <ul class="navbar-nav me-auto">
                        </ul>
                        <ul class="navbar-nav ms-auto">
                            
                            <li class="nav-item">
                                <a  class="nav-link employer-login" href="/login">Employer Login</a>
                            </li>
                            
                        </ul>
            </div>
            
        </nav>

        <header class="masthead faq">
            <div class="overlay"></div>
                <div class="container h-100">
                    <div class="row h-100 text-center">
                    <div class="col-12 bnr-top-pding ">
                            <h1 data-aos="fade-down" >FAQ</h1> 
                    </div>
                    
                    </div>
                </div>
        </header>

        <div style={{maxWidth:'1320px'}} class="container faq-wrpr">
		
		<div class="row">
			<div class="col-md-12">
				
				<div id="faq_accordion">

				  <div class="card">
					<div class="card-header">
					  <a class="btn" data-bs-toggle="collapse" aria-expanded="true" href="#faqCollapseOne">
						What is SHIPCONREP’s purpose?
					  </a>
					</div>
					<div id="faqCollapseOne" class="collapse show" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						<p>It is to allow you to tell your employer something that, for whatever reason, you do not feel comfortable raising either publicly or privately but also saying who you are.</p>
					  </div>
					</div>
				  </div>

				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseTwo">
						Why do employers use it?
					  </a>
					</div>
					<div id="faqCollapseTwo" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Because employers want to hear when employees experience difficulty in doing their work well. Mainly where the above circumstances apply, and not because they suspect widespread discontent.
					  </div>
					</div>
				  </div>

				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseThree">
						Is SHIPCONREP anonymous and confidential?
					  </a>
					</div>
					<div id="faqCollapseThree" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Yes, it is. The employer does not know and will not find out who you are.
					  </div>
					</div>
				  </div>
				  
				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseFour">
						Doesn’t anonymity suggest something untrustworthy?
					  </a>
					</div>
					<div id="faqCollapseFour" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Certainly not.
						<br/>
						There are some issues that, of their nature, are best raised this way. Anonymity is often a good and sometimes a necessary thing. It does not imply sinister context or that there is something objectionable in the act of reporting.
						
					  </div>
					</div>
				  </div>
				  
				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseFive">
						What will the employer think when a report is made like this?
					  </a>
					</div>
					<div id="faqCollapseFive" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						The employer will significantly appreciate the matter being raised. That is what this facility is for.  
						<br/><br/>
						Notions like “snitching on the boss” or “telling on your mates” are as outdated as they are counterproductive, and tackling them is part of what SHIPCONREP is about.
					  </div>
					</div>
				  </div>
				  
				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseSix">
						How can I be sure that there will not, ultimately, be retaliation?
					  </a>
					</div>
					<div id="faqCollapseSix" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Like most, employers subscribing to SHIPCONREP have zero tolerance on this. As well as being counter-cultural, retaliation is misconduct, and any contemplating it would expect a thorough investigation and serious disciplinary measures.
					  </div>
					</div>
				  </div>
				  
				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseSeven">
						When should I use SHIPCONREP?
					  </a>
					</div>
					<div id="faqCollapseSeven" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Whenever you want to raise an issue with your employer in this way, it is available 24 hours a day, year-round.
					  </div>
					</div>
				  </div>
				  
				  <div class="card">
					<div class="card-header">
					  <a class="collapsed btn" data-bs-toggle="collapse" href="#faqCollapseEight">
						Will sending a report make a difference, really?
					  </a>
					</div>
					<div id="faqCollapseEight" class="collapse" data-bs-parent="#faq_accordion">
					  <div class="card-body">
						Certainly, it will. Your employer would not otherwise subscribe to SHIPCONREP. Individual concerns can be considered and suitable, and where necessary discreet, action is taken, or adverse or unhelpful trends can be identified and addressed.
						<br/><br/>
						A report may reveal an entirely new issue, or a new slant on something, resulting in fresh thinking.
					  </div>
					</div>
				  </div>
				</div>
			</div>
		</div>
	
</div>

<footer className="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-2">
                                {/* <img src={require("../assets/images/site/Ship-icon-rep-logo.png")} /> */}
                            </div>
                            <div class="col-md-10 bottom-border">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Shipconrep</h5>
                                        <div class="anchors">
                                        <a  href={RouteConstants.ABOUT}>About Us</a>
                                        <a  href={RouteConstants.FAQ}>FAQs</a>
                                        <a  href={RouteConstants.PRIVACYPOLICY} >Privacy Policy</a>
                                        <a className="contactUs" onClick={ ()=>{setisModalopen(true)}}>Contact Us</a>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>Services</h5>
                                        <div class="anchors">
                                        <a href="/frontend">Web Hotline</a>
							                          <a href="/login">Employer Portal</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <h5>Email ID</h5>
                                        <div class="anchors">
                                            <a href="#">info@shipconrep.com</a>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <hr class="border-white" />
                                    </div>
                                    
                                    <div class="col-md-12 text-center pt-3 pb-5">
                                    <h4>This portal is administered by:</h4>
                                        <p>Shipping Industry Confidential Reporting Limited. Registered in England and Wales: 13395969</p>
                                    </div>
                                    <div class="col-md-12">
					<div class="anchors" style={{display:'flex'}}>
					  <a  href="https://www.facebook.com/people/Shipping-Industry-Confidential-Reporting/100094405543592/"><img className="fbicon" src={require("../assets/images/site/fb.jpg")} /></a>
					  <a  href="https://www.linkedin.com/company/shipping-industry-confidential-reporting-pvt-ltd"><img className="fbicon" src={require("../assets/images/site/linkedin.png")} /></a>
					  </div>
					</div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </footer>
                <Contact isModalopen={isModalopen} setisModalopen={setisModalopen} />
        </>

    )
}
export default Faq;