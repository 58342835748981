const RouteConstants = { 
    ROOT: '/',
    LOGIN: '/login',
    FORGOTPASS: '/forgotpass/:id',
    DASHBOARD:'/dashboard',
    SHIPADMINDASH:'/shipadmin',
    SHIPTYPELIST:'/shiptype',
    RANKLIST:'/rank',
    CATEGORY:'/category',
    COUNTRY:'/country',
    COMPANY:'/company',
    OFFICE:'/office',
    DOC:'/doc',
    USER:'/user',
    FLEET:'/fleet',
    VESSEL:'/vessel',
    ISSUES:'/issues',
    COMPLIANTVIEW:'/issueview/:id',
    COMPLIANTVIEWURL:'/issueview/',
    PUBLICISSUES:'/public_issues',
    PUBLICISSUESVIEW: '/public_issueview/:id',
    PUBLICISSUESVIEWURL: '/public_issueview/',
    REPORT:'/report',
    REPORTDASH:'/reportdash',
    VESSELCHART:'/vesselchart',
    CATEGORYREPORT:'/categoryreport',
    VESSELCOMPANYREPORT:'/vesselcompanyreport',
    NOCODEREPORT:'/nocodereport',
    ABOUT:'/about',
    FAQ:'/faq',
    EMPLOYERFAQ:'/efaq',
    PRIVACYPOLICY:'/policy',
    DEMO:'/demo',
    SURVEYREPORT:'/surveyreport',
    CATEGORYNOCODE:'/categorynocode',
    RANKONBOARDNOCODE:'/ranknocode',
    TOTALREPORT:'/totalreport',
    PROFILE:'/profile'
};
export default RouteConstants